import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { Modal } from 'react-bootstrap';
import React, {useEffect, useState} from 'react'
import { Button, ListGroup } from 'react-bootstrap';
import { Autocomplete, SearchResultItem } from '../Autocomplete';
import { AiOutlineCloseCircle, AiOutlineLoading } from 'react-icons/ai'
import algoliasearch from 'algoliasearch';
import { useNavigate } from 'react-router-dom';
import {addTickersToProfile, startStreamingStock} from '../../api/stocks.api';
const appId = '6TB4CIO1CR';
const apiKey = 'd3b2f08e0daab084743f17b4e351fcbe';
const searchClient = algoliasearch(appId, apiKey);


const AddTicker = ({ profile, show, setShow, stocks, tickers }) => {
    const [showLoading, setLoading] = useState(false)
    const [selectedTickers, setSelectedTickers] = useState(tickers || []);


    useEffect(() => {
        let tickersToAdd = [];
        tickers.forEach((ticker) => {
            if (!selectedTickers.includes(ticker)) {
                tickersToAdd.push(ticker);
            }
        })
        setSelectedTickers([...selectedTickers, ...tickersToAdd]);
        console.log(selectedTickers, tickers)
    }, [tickers])

    const handleAdd = (ticker) => {
        console.log(selectedTickers, ticker)
        setSelectedTickers((previous) => [...previous, ticker])
        console.log(selectedTickers)
        startStreamingStock(ticker)
    }

    const handleDelete = (ticker) => {
        const index = selectedTickers.indexOf(ticker);
        const newArr = [...selectedTickers];
        newArr.splice(index, 1);
        setSelectedTickers(newArr);
    }

    const handleClose = async () => {
        setLoading(true)
        await addTickersToProfile(profile.uid, selectedTickers)
        setLoading(false)
        setShow(false);
        setSelectedTickers([])

    }
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Ticker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Autocomplete className="align-items-center"
                    col={12}
                    openOnFocus={true}
                    stocks={stocks}
                    getSources={({ query }) => [
                        {
                            sourceId: 'products',
                            getItems() {
                                return getAlgoliaResults({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: 'company_details',
                                            query,
                                        },
                                    ],
                                });
                            },
                            templates: {
                                item({ item, components }) {
                                    return <div className='aa-ItemContent' onClick={() => handleAdd(item.ticker)} style={{
                                        zIndex: 99999999
                                    }}>
                                        {item.ticker}
                                    </div>
                                },
                            },
                        },
                    ]}
                />
                <ListGroup className='mt-3'>
                    {
                        selectedTickers.map((el, idx) => (
                            <ListGroup.Item key={`ticker-list-${idx}`} className=' d-flex align-items-center justify-content-between'>
                                {el}
                                <AiOutlineCloseCircle onClick={() => handleDelete(el)} cursor={'pointer'} />
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button disabled={showLoading} variant="primary" onClick={handleClose}>
                    {showLoading ? 'loading...' : ' Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default AddTicker;
