import './JTKAdmin.css';

import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import { Container, Row, Col } from 'react-bootstrap';

import 'firebase/compat/firestore';

import Sidebar from "../../components/sidebar/Sidebar";
import StockCard from "../../components/stock-card/StockCard";
import {Header} from "../../components/header/Header";
import {startStreamingStock, stockStream} from "../../api/stocks.api";

function JTKAdmin() {

  const [stocks, setStocks] = useState([]);
  const [stocksLiveData, setStocksLiveData] = useState({});

  useEffect(() => {

    firebase.firestore().collection("watchlist").onSnapshot((querySnapshot) => {
      var stocksFromDatabase = [];
      var loadStockInfoPromises = [];
      // for each stock from database
      querySnapshot.forEach((doc) => {
        let stock = doc.data();
        startStreamingStock(stock);
        // push the request into array
        loadStockInfoPromises.push(
            Promise.resolve(stocksFromDatabase.push(stock))
        );
      });
      // after all data is loaded, setStocks for UI to access
      return Promise.all(loadStockInfoPromises).then(() => {
        console.log(stocksFromDatabase);
        setStocks(stocksFromDatabase);
        return stocksFromDatabase;
      })
    });

    stockStream.subscribe(updatedStocksData => {
      setStocksLiveData({...updatedStocksData})
    });

    return () => {
      setStocks([]);
      setStocksLiveData({});
    }

  }, []);

  return (
    <div className="JTKAdmin">
      <Row className="m-0">
        <Sidebar />

        <Col className="main-col p-0">
          <Header></Header>

          <div className="pb-5 px-3">
            <Container className="px-0" style={{maxWidth: '100%'}}>
              <Row className="ticker-row">
                <Col className="border">
                  SPY {JSON.stringify((stocksLiveData || {})['SPY'])}
                  <span className="price">$50</span>
                  <span className="percent-change">-5%</span>
                </Col>
                <Col className="border">
                  QQQ {JSON.stringify((stocksLiveData || {})['QQQ'])}
                  <span className="price">$50</span>
                  <span className="percent-change">-5%</span>
                </Col>
                <Col className="border">
                  DIA {JSON.stringify((stocksLiveData || {})['DIA'])}
                  <span className="price">$50</span>
                  <span className="percent-change">-5%</span>
                </Col>
                <Col className="border">
                  Select Ticker
                  <span className="price">$50</span>
                  <span className="percent-change">-5%</span>
                </Col>
                <Col className="border">
                  Select Ticker
                  <span className="price">$50</span>
                  <span className="percent-change">-5%</span>
                </Col>
              </Row>
              <Row className="justify-content-center">
                {stocks.map((stock, index) => {
                  return (
                    <Col className='mb-4 col-md-auto' key={'admin-stock-' + index}>
                      <StockCard stock={stock}></StockCard>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default JTKAdmin;
