import {Col, Row} from "react-bootstrap";
import {getStockChangePercentage, getStockPrice, getStockDelta, getStockDeltaAsFloat} from "../../helpers/StockDataParsing";
import React, {useEffect, useState} from "react";

import './TickerTape.css'
import {db} from "../../firebase";
import firebase from "firebase/compat/app";
import AddTicker from "../add-ticker/add-ticker";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {BiPlus} from "react-icons/bi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

function TickerTape({ stocksLiveData, stocksPreviousDayData, stocksAsObject, profile }) {
    const [show, setShow] = useState(false)
    const [tickers, setTickers] = useState([]);
    const onDragEnd = async (result) => {
        const items = Array.from(tickers);
        const [reorderedItems] = items.splice(result.source.index, 1);
        console.log(items, reorderedItems)
        items.splice(result.destination.index, 0, reorderedItems);
        setTickers(items)
        const docRef = db.doc(`profiles/${firebase.auth().currentUser.uid}`);
        await docRef.update({
            tickerTape: items
        })

    }

    useEffect(() => {
        console.log('updated Profile ticker tape', profile?.tickerTape, tickers)

        if (profile && profile.tickerTape.length > 0) {
            setTickers(profile.tickerTape)
        }
    }, [profile])


    return (
        <div>
            <AddTicker
                profile={profile}
                show={show}
                setShow={setShow}
                stocks={stocksAsObject}
                tickers={tickers}
            />

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='0' direction='horizontal'>
                    {(provided) => (
                        <Row className="ticker-div" {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                tickers.map((el, idx) => (
                                    <Draggable key={el + '-' + idx} draggableId={el} index={idx}>
                                        {(provided) => (
                                            <Col sm={2} className="ticker-item"
                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Row>
                                                    <Col className="ticker-tape-text">
                                                        {el}
                                                        <div>

                                                            {getStockPrice(stocksLiveData, el)}
                                                        </div>
                                                    </Col>

                                                    <div className="arrow col-6 text-center">
                                                        {(getStockDeltaAsFloat(stocksPreviousDayData, stocksLiveData, el) < 0) ? <TiArrowSortedDown style={{ color: 'red' }}></TiArrowSortedDown> : <TiArrowSortedUp style={{ color: 'rgb(70, 255, 70)' }}></TiArrowSortedUp>}
                                                    </div>

                                                    <Col>
                                                        {getStockDelta(stocksPreviousDayData, stocksLiveData, el)}
                                                        <div>
                                                            {getStockChangePercentage(stocksPreviousDayData, stocksLiveData, el)}

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        )}
                                    </Draggable>
                                ))
                            }
                            <BiPlus className='ticker-item col-sm-1 my-3 btn text-light p-0 border-0'
                                    style={{backgroundColor: 'transparent', fontSize: '2em'}}
                                    onClick={() => setShow(true)}></BiPlus>
                            {provided.placeholder}
                        </Row>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )

}

export default TickerTape
