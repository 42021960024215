import { useEffect, useState } from "react";
import { additionalAnyChartLibraries, loadLibraries } from "../../helpers/Helpers";
import moment from "moment";
import { ListGroup } from "react-bootstrap";
import { BsArrowsMove } from "react-icons/bs";
import Hide from "../../molecules/Hide.mole";

const initializeChart = (id) => {
    let anychart = window.anychart;
    anychart.onDocumentReady(function () {
        let element = document.getElementById(id);
        if (element && element.innerHTML !== '') {
            // return;
        }
        // set chart theme
        anychart.theme('darkBlue');
        // The data used in this sample can be obtained from the CDN
        // https://cdn.anychart.com/csv-data/csco-daily.csv
        anychart.data.loadCsvFile(
            'https://cdn.anychart.com/csv-data/csco-daily.csv',
            function (data) {
                // create data table on loaded data
                var dataTable = anychart.data.table();
                dataTable.addData(data);

                // map loaded data for the ohlc series
                var mapping = dataTable.mapAs({
                    open: 1,
                    high: 2,
                    low: 3,
                    close: 4
                });

                // map loaded data for the scroller
                var scrollerMapping = dataTable.mapAs();
                scrollerMapping.addField('value', 5);
                // create stock chart
                var chart = anychart.stock();
                // create first plot on the chart
                var plot = chart.plot(0);
                // set grid settings
                plot.yGrid(true).xGrid(true).yMinorGrid(true).xMinorGrid(true);
                // create EMA indicators with period 50
                plot
                    .ema(dataTable.mapAs({ value: 4 }))
                    .series()
                    .stroke('1.5 #455a64');
                var series = plot.candlestick(mapping);
                series.name('CSCO');
                series.legendItem().iconType('rising-falling');
                // create scroller series with mapped data
                chart.scroller().candlestick(mapping);
                // set chart selected date/time range
                chart.selectRange(moment().subtract(1, 'years').format('yyyy-mm-dd'), moment().format('yyyy-mm-dd'))
                // set container id for the chart
                chart.container(id);
                // initiate chart drawing
                chart.draw();
                // create range picker
                var rangePicker = anychart.ui.rangePicker();
                // init range picker
                rangePicker.render(chart);
                // create range selector
                var rangeSelector = anychart.ui.rangeSelector();
                // init range selector
                rangeSelector.render(chart);
            }
        );
    });
}

export default function Chart({ id = 'default-chart', chartType = 'candlestick' }) {

    const [chartHeight, setChartHeight] = useState(0);

    useEffect(() => {
        loadLibraries(additionalAnyChartLibraries);
        initializeChart(id);

        // }, [window, window.anychart])
    }, []);

    const handleRef = (ref) => {
        if (ref) {
            setChartHeight(ref.clientHeight);
        }
    }

    return (
        <div className="bg-light" ref={handleRef} style={{ height: '100%' }}>
            <Hide open={chartHeight}>
                <div id={id} style={{ height: chartHeight }}></div>
            </Hide>
        </div>
    )
}
