import React, {useEffect} from "react";

import {Autocomplete, SearchResultItem} from "../Autocomplete";
import {getAlgoliaResults} from "@algolia/autocomplete-js";
import algoliasearch from 'algoliasearch';
import '@algolia/autocomplete-theme-classic';
import {addStockToWatchlist} from "../../api/stocks.api";
import {useNavigate} from "react-router-dom";

const appId = '6TB4CIO1CR';
const apiKey = 'd3b2f08e0daab084743f17b4e351fcbe';
const searchClient = algoliasearch(appId, apiKey);

export function Header({stocks, profile}) {
    let navigate = useNavigate();

    useEffect(() => {
    }, [stocks])

    return <header className="JTKAdmin-header px-4 flex-row">
        <Autocomplete className="align-items-center"
            openOnFocus={true}
            stocks={stocks}
            getSources={({ query }) => [
                {
                    sourceId: 'products',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: 'company_details',
                                    query,
                                },
                            ],
                        });
                    },
                    templates: {
                        item({ item, components }) {
                            return <SearchResultItem hit={item} components={components} stocks={stocks} navigate={(ticker) => navigate(ticker)} />;
                        },
                    },
                },
            ]}
        />
        <img className="profile-Image" height="50" src={profile?.profileImage} />
    </header>
}
