import { ListGroup, Table } from "react-bootstrap";
import {
    getStockChangePercentage,
    getStockPrice,
    getStockDelta,
    getStockDeltaAsFloat
} from "../../helpers/StockDataParsing";
import "./Watchlist.css"
import { BsArrowsMove } from "react-icons/bs";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import WidgetHeader from "../../molecules/WidgetHeader.mole";
import {useNavigate} from "react-router-dom";

export default function Watchlist({ previousDayData, stocksLiveData, stocks }) {
    let navigate = useNavigate();

    return (
        // <div className="h-100 d-flex flex-column bg-light">
        //     <WidgetHeader label="Watchlist" />
        //     <div className="bg-light flex-1 overflow-auto">
                <ListGroup className="watchlist">

                    {stocks.map((stock, index) => (
                        <ListGroup.Item key={'watchlist-item-' + index} className="d-flex justify-content-between bg-light text-dark row mx-auto col-12 p-0" onClick={() => navigate(stock.ticker)}>
                            <div className="watchlist-ticker-currentprice col-3">
                                <span>
                                    {stock.ticker}
                                </span>
                                <span>
                                    {getStockPrice(stocksLiveData, stock.ticker)}
                                </span>
                            </div>

                            <div className="arrow col-1 text-center">
                                {(getStockDeltaAsFloat(previousDayData, stocksLiveData, stock.ticker) < 0) ? <TiArrowSortedDown style={{ color: 'red' }}></TiArrowSortedDown> : <TiArrowSortedUp style={{ color: 'rgb(70, 255, 70)' }}></TiArrowSortedUp>}
                            </div>

                            <div className="watchlist-change col-5 px-0">
                                <span>
                                    {getStockDelta(previousDayData, stocksLiveData, stock.ticker)}
                                </span>
                                <span>
                                    {getStockChangePercentage(previousDayData, stocksLiveData, stock.ticker)}
                                </span>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
        //     </div>
        // </div>
    )
}
