import { ListGroup, Table } from "react-bootstrap";
import {
    getStockChangePercentage,
    getStockPrice,
    getStockDelta,
    getStockDeltaAsFloat, getMarketMoversDelta, getMarketMoverChangePercentage
} from "../../helpers/StockDataParsing";
import "./MarketMovers.css"
import { BsArrowsMove } from "react-icons/bs";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import WidgetHeader from "../../molecules/WidgetHeader.mole";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function MarketMovers({ stocksLiveData, gainers, losers }) {
    let navigate = useNavigate();

    useEffect(() => {
        console.log(gainers)
    }, [])

    return (
        // <div className="h-100 d-flex flex-column bg-light">
        //     <WidgetHeader label="MarketMovers" />
        //     <div className="bg-light flex-1 overflow-auto">
        <div className="row mx-auto">
                <ListGroup className="market-movers col p-0">
                    <ListGroup.Item className="d-flex justify-content-between bg-light text-dark title">
                        <strong>Gainers</strong>
                    </ListGroup.Item>

                    {(gainers || []).map((stock, index) => (
                        <ListGroup.Item key={'market-movers-item-' + index} className="d-flex justify-content-between bg-light text-dark row mx-auto col-12 p-0" onClick={() => navigate(stock.ticker)}>
                            <div className="market-movers-ticker-currentprice col-3">
                                <span>
                                    {stock.ticker}
                                </span>
                                <span>
                                    {getStockPrice(stocksLiveData, stock.ticker)}
                                </span>
                            </div>

                            <div className="arrow col-1 text-center" style={{maxWidth: '50px'}}>
                                { stock.todaysChangePerc < 0 ? <TiArrowSortedDown style={{ color: 'red' }}></TiArrowSortedDown> : <TiArrowSortedUp style={{ color: 'rgb(70, 255, 70)' }}></TiArrowSortedUp>}
                            </div>

                            <div className="market-movers-change col-5 px-0">
                                <span>
                                    {getMarketMoversDelta(stock)}
                                </span>

                                <span>
                                    {getMarketMoverChangePercentage(stock)}
                                </span>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>

                <ListGroup className="market-movers col p-0">
                    <ListGroup.Item className="d-flex justify-content-between bg-light text-dark title">
                        <strong>Losers</strong>
                    </ListGroup.Item>

                    {(losers || []).map((stock, index) => (
                        <ListGroup.Item key={'market-movers-item-' + index} className="d-flex justify-content-between bg-light text-dark row mx-auto col-12 p-0" onClick={() => navigate(stock.ticker)}>
                            <div className="market-movers-ticker-currentprice col-3">
                                <span>
                                    {stock.ticker}
                                </span>
                                <span>
                                    {getStockPrice(stocksLiveData, stock.ticker)}
                                </span>
                            </div>

                            <div className="arrow col-1 text-center" style={{maxWidth: '50px'}}>
                                { stock.todaysChangePerc < 0 ? <TiArrowSortedDown style={{ color: 'red' }}></TiArrowSortedDown> : <TiArrowSortedUp style={{ color: 'rgb(70, 255, 70)' }}></TiArrowSortedUp>}
                            </div>

                            <div className="market-movers-change col-5 px-0">
                                <span>
                                    {getMarketMoversDelta(stock)}
                                </span>

                                <span>
                                    {getMarketMoverChangePercentage(stock)}
                                </span>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        //     </div>
        // </div>
    )
}
