export function focusElement(contentEditableElement) {
    if (document.createRange) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(contentEditableElement);
        range.collapse(false);
        selection && selection.removeAllRanges();
        selection && selection.addRange(range);
    }
}

export function insertElementAtCaret(element) {
    if (window.getSelection) {

        const sel = window.getSelection();
        if (sel?.getRangeAt && sel.rangeCount) {
            let range = sel.getRangeAt(0);
            range.deleteContents();

            const el = document.createElement("div");
            el.insertAdjacentElement("afterbegin", element)
            const frag = document.createDocumentFragment();
            let node;
            let lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);

            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }
}