import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Sidebar from '../../components/sidebar/Sidebar';
import Chat from '../../components/chat/Chat';

const ChatPage = () => {
  useEffect(() => {});

  return (
    <div className='Dashboard'>
      <Row className='m-0' style={{ height: '100vh' }}>
        <Sidebar />
        <Col className='ps-0'>
          <Chat />
        </Col>
      </Row>
    </div>
  );
};

export default ChatPage;
