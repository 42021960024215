import 'firebase/compat/storage';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Button, Spinner, Table, Modal } from 'react-bootstrap';
import Sidebar from '../../components/sidebar/Sidebar';

import './Profile.css';
import { db, storage } from '../../firebase';
import PostEditor from '../../components/post-editor/PostEditor.component';
import Post from '../../components/post/Post.component';
import { savePost } from '../../api/posts.api';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';
import { collection, doc, limit, orderBy, query } from 'firebase/firestore';
import { BiEdit, BiPencil } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import Hide from '../../molecules/Hide.mole';
import moment from 'moment';

function ProfileHeader({ profile }) {
  const [profileBlob, setProfileBlob] = useState('');
  const [file, setFile] = useState();

  const hiddenFileInput = React.useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const blob = URL.createObjectURL(fileUploaded);
    setProfileBlob(blob);
    setFile(fileUploaded);
  };

  let profilePic = profileBlob || profile?.profileImage || 'https://via.placeholder.com/150';

  return (
    <React.Fragment>
      <Row className='m-0'>
        <div className='position-relative profile-image-container'>
          <div className='profile-active'>
            <img src={profilePic} className='profile-image ' alt='' />
          </div>
        </div>
        <Col className='pt-5'>
          <h2>
            <b>{profile?.name}</b>
          </h2>
          <p className='mb-0'>100 Followers</p>
          <p className='mb-0'>88 Friends</p>
          <p className='mb-0'>Stocks, Options, Forex, Crypto</p>
        </Col>
        <Col className='pt-5'>
          <PostEditor
            title='Create New Post'
            btnTitle='Publish Post'
            createPost={(post) => {
              savePost(profile, post);
            }}
            profile={profile}
            button={<Button variant='primary'>Add Post +</Button>}
            preview
          />
          <button className='btn btn-primary'>Edit Profile</button>
          <div className='position-relative'>
            <input
              type='file'
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ opacity: '0', cursor: 'pointer', height: '100%', width: '100%' }}
              className='position-absolute'
            />
            <button className='btn btn-primary'>Edit Profile Picture</button>
          </div>
        </Col>
      </Row>

      <Row className='border m-0 '>
        <Col className='col-6 offset-3 text-center d-flex justify-content-center'>
          <Link className='btn btn-link text-dark text-decoration-none' to='/profile/posts'>
            Posts
          </Link>
          <Link className='btn btn-link text-dark text-decoration-none' to='/profile/about'>
            About
          </Link>
          <Link className='btn btn-link text-dark text-decoration-none' to='/profile/portfolio'>
            Portfolio
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function ProfileRoot({ profile }) {
  const posts = useOnSnapshot(collection, ['profiles', profile?.uid, 'posts'], [], (ref) => {
    return query(ref, orderBy('createdAt', 'desc'));
  });
  const friends = useOnSnapshot(collection, ['profiles', profile?.uid, 'friends'], []);

  // const [currentPost, setCurrentPost] = useState(null);

  const dates = ['5/6/22', '27/5/22', '4/6/22'];
  const tickers = ['NVDA', 'DKNG', 'SPY'];
  const status = ['Buy to Open', 'Buy to Open', 'Sell to Open'];
  const quantities = [50, 60, 10];
  const price = [4.2, 13.2, 40.2];

  return (
    <Container>
      <ProfileHeader profile={profile} />
      <Row className='m-0'>
        <Col className='col-6'>
          <Col className='border p-1 mt-4'>
            <h4 className='m-3 text-center'>Recent Trades</h4>
            <div className='d-flex justify-content-around text-center'>
              <div>
                <p className='fw-bold'>Date</p>
                {dates.map((date) => (
                  <p>{date}</p>
                ))}
              </div>
              <div>
                <p className='fw-bold'>Ticker</p>
                {tickers.map((ticker) => (
                  <p>{ticker}</p>
                ))}
              </div>
              <div>
                <p className='fw-bold'>Open/Close</p>
                {status.map((stat) => (
                  <p>{stat}</p>
                ))}
              </div>
              <div>
                <p className='fw-bold'>Qty</p>
                {quantities.map((qt) => (
                  <p>{qt}</p>
                ))}
              </div>

              <div>
                <p className='fw-bold'>Price</p>
                {price.map((price) => (
                  <p>{price}</p>
                ))}
              </div>
            </div>
            {/* <Table striped hover className='mb-0' title='Recent Trades'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Ticker</th>
                  <th>Open/Close</th>
                  <th>Qty</th>
                  <th>Price</th>
                </tr>
              </thead>
              <div className='d-flex justify-content-space-between'>
                <tbody>
                  <tr>
                    <td>5/6/22</td>
                    <td>NVDA</td>
                    <td>Buy to Open</td>
                    <td>50</td>
                    <td>$4.20</td>
                  </tr>
                  <tr>
                    <td>4/6/22</td>
                    <td>DKNG</td>
                    <td>Buy to Open</td>
                    <td>60</td>
                    <td>$13.20</td>
                  </tr>
                  <tr>
                    <td>27/5/22</td>
                    <td>SPY</td>
                    <td>Sell to Open</td>
                    <td>10</td>
                    <td>$40.20</td>
                  </tr>
                </tbody>
              </div>
            </Table> */}
          </Col>
          <Col className='border p-4 my-4'>
            <div>
              <div>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <h5>Friends</h5>
                    <p className=''>{friends.length}</p>
                  </div>
                  {friends.length > 4 && <button className='btn'>See All</button>}
                </div>

                <div className='d-flex flex-wrap justify-content-evenly'>
                  {friends.map((friend, i) => (
                    <div className='text-center p-2'>
                      <div className='profile-active small'>
                        <img
                          width={80}
                          height={80}
                          className='rounded-circle'
                          src={friend.image}
                          alt='profile img'
                        />
                      </div>
                      <p className='mt-1'>{friend.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Col>
        <Col className='col-6 mt-4'>
          {posts.map((post) => (
            <Col key={post.id} className=''>
              <Post profile={profile} post={post}></Post>
            </Col>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

function ExperienceView({ label, title }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button variant='primary' onClick={handleShow}>
        {label}
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Title</Form.Label>
            <Form.Control placeholder='title...' />
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Employment Type</Form.Label>
            <Form.Control placeholder='employment type...' />
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Company Name</Form.Label>
            <Form.Control placeholder='name of your company...' />
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Location</Form.Label>
            <Form.Control placeholder='location of your company...' />
          </Form.Group>

          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 w-25 me-3'>Start Date</Form.Label>

            <div className='w-100 d-flex gx-5'>
              <Form.Control placeholder='month...' className='me-3' />
              <Form.Control placeholder='yaer...' />
            </div>
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 w-25 me-3'>End Date</Form.Label>

            <div className='w-100 d-flex gx-5'>
              <Form.Control placeholder='month...' className='me-3' />
              <Form.Control placeholder='year...' />
            </div>
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Industry</Form.Label>
            <Form.Control placeholder='industry...' />
          </Form.Group>

          <Form.Group className='mb-3 d-flex'>
            <Form.Label className='mb-0 me-3 w-25'>Description</Form.Label>
            <Form.Control placeholder='description...' as='textarea' rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary'>{label}</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

function EducationView({ label, title }) {
  const [show, setShow] = useState(false);

  console.log(show);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button variant='primary' onClick={handleShow}>
        {label}
      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>School</Form.Label>
            <Form.Control placeholder='school name...' />
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Degree</Form.Label>
            <Form.Control placeholder='degree...' />
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 me-3 w-25'>Field Of Study</Form.Label>
            <Form.Control placeholder='field...' />
          </Form.Group>

          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 w-25 me-3'>Start Date</Form.Label>

            <div className='w-100 d-flex gx-5'>
              <Form.Control placeholder='month...' className='me-3' />
              <Form.Control placeholder='yaer...' />
            </div>
          </Form.Group>
          <Form.Group className='mb-3 d-flex align-items-center'>
            <Form.Label className='mb-0 w-25 me-3'>End Date</Form.Label>

            <div className='w-100 d-flex gx-5'>
              <Form.Control placeholder='month...' className='me-3' />
              <Form.Control placeholder='year...' />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary'>{label}</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

function About({ profile }) {
  return (
    <div className='container'>
      <ProfileHeader profile={profile} />
      <div className='row mt-5'>
        <div className='col border rounded p-3'>
          <div className='d-flex justify-content-between'>
            <h5>EDUCATION</h5>

            <div className='d-flex'>
              <EducationView title='Add Education' label='ADD' />
              <EducationView title='Edit Education' label='EDIT' />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col border rounded p-3'>
          <div className='d-flex justify-content-between'>
            <h5>Work Experience</h5>

            <div className='d-flex'>
              <ExperienceView title='Add Experience' label='ADD' />
              <ExperienceView title='Edit Experience' label='EDIT' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProfileViewMini() {
  const { id } = useParams();
  const profile = useOnSnapshot(doc, ['profiles', id], null);

  return (
    <React.Fragment>
      <Container>
        <Row className='m-0'>
          <div className='position-relative profile-image-container'>
            <div className='profile-active'>
              <img src={profile?.profileImage} className='profile-image ' />
            </div>
          </div>
          <Col className='pt-5'>
            <h2>
              <b>{profile?.name}</b>
            </h2>
            <p className='mb-0'>100 Followers</p>
            <p className='mb-0'>88 Friends</p>
            <p className='mb-0'>Stocks, Options, Forex, Crypto</p>
          </Col>
        </Row>
      </Container>
      <Row className='border m-0 '>
        <Col className='col-6 offset-3 text-center d-flex justify-content-center'>
          <Link className='btn btn-link text-dark text-decoration-none' to='posts'>
            Posts
          </Link>
          <Link className='btn btn-link text-dark text-decoration-none' to='about'>
            About
          </Link>
          <Link className='btn btn-link text-dark text-decoration-none' to='portfolio'>
            Portfolio
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const Profile = ({ profile }) => {
  return (
    <div className='Dashboard'>
      <Row className='m-0'>
        <Sidebar />
        <Col className='page p-0'>
          <Routes>
            <Route path='/' element={<ProfileRoot profile={profile} />} />
            <Route path='/posts' element={<ProfileRoot profile={profile} />} />
            <Route path='/about' element={<About profile={profile} />} />
            <Route path='/portfolio' element={<h1>Portfolio</h1>} />
            <Route path='/:id' element={<ProfileViewMini />} />
          </Routes>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
