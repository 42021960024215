import clsx from "clsx";
import { ListGroup } from "react-bootstrap";
import ClickAwayListener from "../click-away-listener/ClickAwayListener.component";

function MentionPanel({ profiles,className, onMention = () => { },onClickAway = ()=>{} },) {

    const members = Object.keys(profiles).map(key => profiles[key]);

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div className={clsx("position-absolute bg-white shadow w-100 start-0 rounded p-4",className)}>
                <h3>Members</h3>
                <ListGroup>
                    {members.map(member => (
                        <ListGroup.Item onClick={() => onMention(member)} as="button" className="text-start opacity-50:active" key={member.id}>
                            {member.name}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </ClickAwayListener>
    )
}

export default MentionPanel;