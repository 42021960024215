import { useRef, useState } from "react";

function useWidth(mobileWidth) {
    // const ref = useRef(null);
    const [width,setWidth] = useState(0);

    const handleRef = (ref)=>{
        if (ref) {
            setWidth(ref.clientWidth,width)
        }
    }

    return [width,handleRef,width <= mobileWidth ]
}

export default useWidth;
