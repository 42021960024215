import firebase from "firebase/compat/app";
import {io} from "socket.io-client";
import {Observable} from "rxjs";
import {getProfileLocally} from "./profile.api";
let isTest = window.location.hostname.includes('localhost');
const testSocket = 'ws://localhost:8080'
const prodSocket = 'wss://automated-stock-trading.uc.r.appspot.com/'
// const socket = io(`${isTest ? testSocket : prodSocket}`,  { transports : ['websocket'] });
const socket = {
    on: (event, callback) => {},
    off: (event, callback) => {}
}

let globalWatchlist = [];
export let stockData = {};
let stockDataLoading = {};
export const stockStream = new Observable(subscriber => {
    setInterval(() => {
        if (!Object.keys(stockData).length) return;
        subscriber.next(stockData);
    }, 1000);
});

export function addStockToWatchlist(stock) {
    // Add a new document in collection "cities"
    startStreamingStock(stock);
    firebase.firestore().collection("watchlist").doc(stock.ticker).set({
        ticker: stock.ticker
    }).then(() => {
        return firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).collection("watchlist").doc(stock.ticker).set({
            ticker: stock.ticker
        }).then(() => {
            alert('Successfully saved data');
        });
    }).catch((error) => {
        console.error("Error writing document: ", error);
        alert('Error saving data');
    });
}

export function removeStockFromWatchlist(stock) {
    // Add a new document in collection "cities"
    return firebase.firestore().collection("profiles").doc(firebase.auth().currentUser.uid).collection("watchlist").doc(stock.ticker).delete().then(() => {
        const { tickers } = getProfileLocally();
        if (!tickers || (tickers && !tickers.includes(stock.ticker))) {
            stopStreamingStock(stock);
        }
        alert('Successfully removed data');
    }).catch((error) => {
        console.error("Error deleting document: ", error);
        alert('Error deleting data');
    });
}

export function startStreamingStock(stock) {
    if (stockDataLoading[stock.ticker]) {
        return;
    }
    console.log('now streaming ', stock.ticker)
    stockDataLoading[stock.ticker] = true;
    // receive a message from the server


    socket.on("A." + stock.ticker || stock, (data) => {
        if (typeof data === 'object') stockData[stock.ticker] = data;
    });
    // firebase.firestore().collection("endOfDay").get().then((querySnapshot) => {
    //   var stocksFromDatabase = [];
    // for each stock from database
    // querySnapshot.forEach((doc) => {
    //   stocksFromDatabase.push(doc.data());
    // })
    // });
}

export function startStreamingMarketMovers() {
    if (stockDataLoading['marketMovers']) {
        return;
    }
    stockDataLoading['marketMovers'] = true;
    // receive a message from the server


    socket.on('marketMovers', (data) => {
        if (typeof data === 'object') stockData['marketMovers'] = data;
    });
}

export function stopStreamingStock(stock) {
    socket.off("A." + stock.ticker || stock, () => {
        delete stockData[stock.ticker]
    });
}

export function stopStreamingMarketMovers() {
    socket.off('marketMovers', () => {
        delete stockData['marketMovers']
    });
}


export const addTickersToProfile = async (id, tickers) => {
    try {
        for (var x = 0; x < tickers.length; x++) {
            console.log(tickers[x])
            if (!globalWatchlist.includes(tickers[x])) {
                await firebase.firestore().collection('watchlist').doc(tickers[x]).set({ticker: tickers[x]});
            }
        }
        const docRef = firebase.firestore().collection("profiles").doc(id);
        await docRef.update({
            // tickerTape: firebase.firestore.FieldValue.arrayUnion(...tickers)
            tickerTape: tickers
        })

    } catch (err) {
        console.log(err)
    }

}
