import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Post from '../../components/post/Post.component';
import Sidebar from '../../components/sidebar/Sidebar';
import { loadPosts } from '../../api/posts.api';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';
import { collection, orderBy, query } from 'firebase/firestore';

function Posts({ profile }) {
  const posts = useOnSnapshot(collection, ['posts'], [], (ref) => {
    return query(ref, orderBy('createdAt', 'desc'));
  });

  return (
    <div>
      <Row className='m-0' style={{ height: '100vh' }}>
        <Sidebar />

        <Col className='bg-white'>
          <div className='mt-3'>
            {(posts || []).map((post, i) => (
              <Post key={i} profile={profile} post={post} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Posts;
