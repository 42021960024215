import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Landing from './pages/landing/Landing'
import StocksDashboard from "./pages/stocks-dashboard/StocksDashboard";
import OptionsDashboard from "./pages/options-dashboard/OptionsDashboard";
import JTKAdmin from './pages/jtk-admin/JTKAdmin';
import ViewStock from "./pages/view-stock/ViewStock";
import moment from "moment";
import { defaultAnyChartLibraries, loadLibraries } from "./helpers/Helpers";
import Chat from "./pages/chat/Chat";
import Charts from "./pages/charts/Charts";
import Settings from './pages/settings/Settings';
import firebase from 'firebase/compat/app';
import Friends from "./pages/friends/Friends";
import { setProfileLocally } from "./api/profile.api";
import Profile from "./pages/profile/Profile";
import Posts from './pages/posts/Posts.page';
import {Toaster} from "react-hot-toast";


function App() {
  let navigate = useNavigate();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    loadLibraries(defaultAnyChartLibraries)
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user && !profile) {
        firebase.firestore().collection('profiles').doc(user.uid).onSnapshot((profileSnapshot) => {
          let name = '';
          // if profile doesn't exist
          if (!profileSnapshot.data()) {
            // create a new profile
            const profileToSave = {
              email: user.email,
              emailVerified: user.emailVerified,
              name: user.displayName,
              uid: user.uid,
              tickerTape: ['SPY', 'QQQ', 'DIA'],
              tier: 'trial',
              trialEnd: (moment().add(7, 'days').unix() * 1000),
            }
            name = user.displayName;
            // set new profile for the user
            firebase.firestore().collection('profiles').doc(user.uid).set(profileToSave)
            // and add default stocks to their watchlist
            firebase.firestore().collection('profiles').doc(user.uid).collection('watchlist').doc('SPY').set({ ticker: 'SPY' })
            firebase.firestore().collection('profiles').doc(user.uid).collection('watchlist').doc('QQQ').set({ ticker: 'QQQ' })
            firebase.firestore().collection('profiles').doc(user.uid).collection('watchlist').doc('DIA').set({ ticker: 'DIA' })
            setProfile(profileToSave);
            setProfileLocally(profileToSave);
          } else {
            const profile = profileSnapshot.data();
            setProfile(profile);
            setProfileLocally(profile);
          }
          console.log('updatedProfile')

          // set the user activity as active
          name = name || profileSnapshot.data().name;
          firebase.firestore().collection('activeUsers').doc(user.uid).set({
            name: name,
            timestamp: moment().utc().valueOf()
          });
          // then setup a recurring interval to do the same as above
          setInterval(() => {
            firebase.firestore().collection('activeUsers').doc(user.uid).set({
              name: name,
              timestamp: moment().utc().valueOf()
            });
            // every 10 minutes
          }, 600000);
        });
      }
      console.log(user);
      if (user && (!window.location.pathname || window.location.pathname === '/')) {
        navigate('profile');
      } else if (!user) {
        navigate('/');
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);



  return (
      <div>
        <div><Toaster/></div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="dashboard" element={<StocksDashboard profile={profile} />} />
          <Route path="dashboard/:ticker" element={<ViewStock profile={profile} />} />
          <Route path="chat" element={<Chat />} />
          <Route path="charts" element={<Charts />} />
          <Route path="options" element={<OptionsDashboard />} />
          <Route path="jtk-admin" element={<JTKAdmin />} />
          <Route path="/profile/*" element={<Profile profile={profile} />} />
          <Route path="settings" element={<Settings profile={profile} setProfile={setProfile} />} />
          <Route path="/posts" element={<Posts profile={profile} />} />
          <Route path="/friends" element={<Friends profile={profile} />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </div>
  );
}

export default App;
