export function isScriptLoaded(url, container = null) {
    if (!url) url = "http://xxx.co.uk/xxx/script.js";
    var scripts = container ?
        container.querySelectorAll(`script[src="${url}"]`)
        : document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src === url) return true;
    }
    return false;
}

export function isCSSLoaded(url) {
    var links = document.getElementsByTagName('link');
    let linkExists = false;
    for(var x = 0; x < links.length; x++){
        if(links[x].href.indexOf(url) !== -1){
            linkExists = true;
        }
    }
    return linkExists;
}

export function loadExternalScript(url, callback = () => {}) {
    if (isScriptLoaded(url)) {
        return;
    }
    var script = document.createElement('script');
    script.onload = callback;
    script.src = url;
    document.head.appendChild(script);
}

export function loadExternalCSS(url) {
    if (isCSSLoaded(url)) {
        return;
    }
    var link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);
}

export let defaultAnyChartLibraries = [
    // {type: 'script', url: "https://cdn.anychart.com/releases/8.11.0/js/anychart-core.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/8.11.0/js/anychart-bundle.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/8.11.0/js/anychart-base.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/js/anychart-base.min.js"},
];

export let additionalAnyChartLibraries = [
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/js/anychart-ui.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/js/anychart-exports.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/js/anychart-stock.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/js/anychart-data-adapter.min.js"},
    // {type: 'script', url: "https://cdn.anychart.com/releases/v8/themes/dark_blue.min.js"},
    // {type: 'css', url: "https://cdn.anychart.com/releases/v8/css/anychart-ui.min.css"},
    // {type: 'css', url: "https://cdn.anychart.com/releases/v8/fonts/css/anychart-font.min.css"}
]

export function loadLibraries(libraries = defaultAnyChartLibraries) {
    libraries.forEach((source) => {
        return source.type === 'script' ? loadExternalScript(source.url) : loadExternalCSS(source.url);
    })
}

export function getStockCardWidgetOptions(type, stock) {
    if (type === 'tvSentiment') {
        return `{
            "interval": "1m",
            "width": "100%",
            "isTransparent": false,
            "height": "100%",
            "symbol": "NASDAQ:${stock.ticker}",
            "showIntervalTabs": true,
            "locale": "en",
            "colorTheme": "light"
        }`
    } else {
         return `{
            "symbol": "BATS:${stock.ticker}",
            "width": "100%",
            "height": "100%",
            "locale": "en",
            "dateRange": "12M",
            "colorTheme": "light",
            "trendLineColor": "rgba(41, 98, 255, 1)",
            "underLineColor": "rgba(41, 98, 255, 0.3)",
            "underLineBottomColor": "rgba(41, 98, 255, 0)",
            "isTransparent": false,
            "autosize": true,
            "largeChartUrl": ""
         }`
    }
}

export function cleanEmpty(obj) {
    if (Array.isArray(obj)) {
        return obj
            .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
            .filter(v => !(v == null || v == undefined));
    } else {
        return Object.entries(obj)
            .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
            .reduce((a, [k, v]) => ((v == null || v == undefined) ? a : (a[k]=v, a)), {});
    }
}
