import { BsHeartFill, BsThreeDots } from 'react-icons/bs';
import { BiDislike, BiLike, BiMessage, BiUser } from 'react-icons/bi';
import { BsHeart } from 'react-icons/bs';
import { FaRetweet } from 'react-icons/fa';
import { FiShare } from 'react-icons/fi';
import Hide from '../../molecules/Hide.mole';
import './Post.scss';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { loadUserDetails } from '../../api/profile.api';
import { likePost, commentPost } from '../../api/posts.api';
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  Image,
  ListGroup,
  NavDropdown,
} from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import PostEditor from '../post-editor/PostEditor.component';
import { db } from '../../firebase';

function CommentView({ comment, userName, profileImg }) {
  return (
    <div className='comment-view'>
      <div className='d-flex mt-1'>
        <img src={profileImg} />
        <div className='ms-2'>
          <h6 className='mb-1'>{userName}</h6>
          <p className='mb-0 text-muted'>{comment}</p>
          <div className='d-flex align-items-center mt-1 '>
            <div className='cursor-pointer opacity-50:active select-none'>
              <BiLike />
              <span className='ms-1'>0</span>
            </div>
            <span className='mx-2'>:</span>
            <div className='cursor-pointer opacity-50:active select-none'>
              <BiDislike />
              <span className='ms-1'>0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CommentSection({ profile, post }) {
  const [comment, setComment] = useState();

  const comments = useOnSnapshot(collection, ['posts', post.id, 'comments'], [])
    .filter((item) => item.comment !== '')
    .map((item) => item.comment);

  console.log(comments);

  const sendComment = () => {
    commentPost(profile, post, comment);
  };

  return (
    <div className=''>
      <div className='d-flex align-items-center mt-3'>
        <FormControl
          placeholder='write a comment...'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button disabled={!Boolean(comment)} onClick={sendComment}>
          Send
        </Button>
      </div>

      <ListGroup className=''>
        {/* {comments.map((comment) => (
          <ListGroup.Item>
            <CommentView
              comment={comment}
              userName={profile.name}
              profileImg={profile.profileImage}
            />
          </ListGroup.Item>
        ))} */}
      </ListGroup>
    </div>
  );
}

function SharePost({ post, profile }) {
  const [show, setShow] = useState(false);

  return (
    <PostEditor
      title='Share Post To Everyone'
      btnTitle='Share'
      button={
        <div className='icon'>
          <FaRetweet size='20px' color='grey' />
          <p>0</p>
        </div>
      }
      profile={profile}
      createPost={() => {}}
    >
      <div className='mt-4'>
        <Post post={post} profile={profile} />
      </div>
    </PostEditor>
  );
}

const Post = ({ profile, post }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [loadedPost, setLoadedPost] = useState({});
  const [commentsOpen, setCommentsOpen] = useState(false);
  const likes = useOnSnapshot(collection, ['posts', post.id, 'likes'], [])
    .filter((item) => item.liked)
    .map((item) => item.uid);

  const comment = useOnSnapshot(collection, ['posts', post.id, 'comments'], [])
    .filter((item) => item.comment !== '')
    .map((item) => item.comment);

  const userId = firebase.auth().currentUser.uid;
  const isLiked = likes?.includes(profile?.uid);

  // Deleting on both the post collection and profile nested post collection!!
  const profilePostRef = doc(db, 'profiles', userId, 'posts', post.id);
  const postRef = doc(db, 'posts', post.id);

  const deletePost = async () => {
    Promise.all([deleteDoc(profilePostRef), deleteDoc(postRef)]);
  };

  useEffect(() => {
    if (post.uid && !post.name && !post.profileImage) {
      return loadUserDetails(post.uid).then((res) => {
        setLoadedPost({
          ...post,
          name: res.name,
          profileImage: res.profileImage,
        });
      });
    }
  }, [post]);

  return (
    <div className='post-container border p-4'>
      <div className='layout'>
        <div className='imgContainer profile-active small'>
          <img className='user-img' src={loadedPost.profileImage} alt='user avatar' />
        </div>
        <div className='post-container'>
          <div className='user-details'>
            <p>{loadedPost.name}</p>
            <span>{moment(loadedPost.createdAt).format()}</span>
          </div>
          <div className='post'>
            <div className='' dangerouslySetInnerHTML={{ __html: loadedPost.html }}></div>
            <Hide open={loadedPost?.attachments && loadedPost?.attachments[0]}>
              <div className='post-img-container'>
                <img
                  className='post-img'
                  src={loadedPost?.attachments && loadedPost?.attachments[0]}
                  alt='post img'
                />
              </div>
            </Hide>
            <div className='icon-container'>
              <div className='icon' onClick={() => setCommentsOpen((pre) => !pre)}>
                <BiMessage size='20px' color='grey' />
                <p>{comment.length}</p>
              </div>
              <SharePost profile={profile} post={post} />
              <div
                className='icon'
                onClick={() => {
                  likePost(profile, post, isLiked);
                }}
              >
                <BsHeartFill size='20px' color={isLiked ? 'red' : 'grey'} />
                <p>{likes.length}</p>
              </div>
            </div>
          </div>
        </div>

        {/* showing menu to the owner of the user only */}
        {userId === post.uid && (
          <div className='menu position-relative'>
            <BsThreeDots onClick={() => setIsClicked(!isClicked)} />

            {isClicked && (
              <div
                className='d-flex flex-column border rounded-3 bg-white position-absolute p-2 gap-1 end-0'
                style={{ width: 'max-content' }}
              >
                <div className='px-1' onClick={deletePost}>
                  Delete
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Hide open={commentsOpen}>
        <CommentSection profile={profile} post={post} />
      </Hide>
    </div>
  );
};
export default Post;
