import moment from 'moment';
import { db } from '../firebase';
import { toast } from 'react-hot-toast';

let posts = [];

const loadPostDetails = (profile, post) => {
  console.log(profile);
  return Promise.all([
    db
      .collection('posts')
      .doc(post.id)
      .collection('comments')
      .get()
      .then((comments) => {
        post.comments = comments.docs.map((comment) => {
          return comment.data();
        });
      })
      .then((comments) => {
        post.comments = comments;
      }),
    db
      .collection('posts')
      .doc(post.id)
      .collection('likes')
      .get()
      .then((likes) => {
        post.likes = likes.docs.map((like) => {
          like = like.data();
          if (like.uid === profile.uid && like.liked) {
            post.liked = true;
          }
          return like;
        });
      })
      .then((likes) => {
        post.likes = likes || [];
      }),
  ]).then(() => {
    posts.push(post);
  });
};

export const loadPosts = (profile, callback) => {
  return db.collection('posts').onSnapshot((docs) => {
    posts = [];
    let postPromises = [];
    docs.forEach((doc) => {
      let post = doc.data();
      post.id = doc.id;
      postPromises.push(loadPostDetails(profile, post));
    });
    return Promise.all(postPromises).then(() => {
      return callback(posts);
    });
  });
};

export const savePost = (profile, post) => {
  const now = moment().utc().valueOf();
  const postID = profile.uid + '-' + now;
  const postPayload = {
    html: post,
    createdAt: now,
    uid: profile.uid,
    id: postID,
  };
  return Promise.all([
    db.collection('posts').doc(postID).set(postPayload),
    db.collection('profiles').doc(profile.uid).collection('posts').doc(postID).set(postPayload),
  ])
    .then(() => {
      console.log('post saved');
      return postPayload;
      toast.success('Your post has been created!');
    })
    .catch((err) => {
      console.log(err);
      toast.error('Something went wrong!');
      return null;
    });
};

export const likePost = (profile, post, isLiked) => {
  const now = moment().utc().valueOf();
  const likePayload = {
    uid: profile.uid,
    createdAt: now,
    liked: true,
  };
  if (isLiked) {
    likePayload.liked = false;
    return Promise.all([
      db.collection('posts').doc(post.id).update({
        lastUpdatedAt: now,
      }),
      db.collection('posts').doc(post.id).collection('likes').doc(profile.uid).set(likePayload),
      db
        .collection('profiles')
        .doc(profile.uid)
        .collection('posts')
        .doc(post.id)
        .collection('likes')
        .doc(profile.uid)
        .set(likePayload),
      db
        .collection('profiles')
        .doc(profile.uid)
        .collection('engagement')
        .doc(post.uid)
        .collection('likes')
        .doc(post.id)
        .set({
          lastUpdatedAt: now,
          liked: likePayload.liked,
          type: 'post',
        }),
    ]);
  }
  return Promise.all([
    db.collection('posts').doc(post.id).update({
      lastUpdatedAt: now,
    }),
    db.collection('posts').doc(post.id).collection('likes').doc(profile.uid).set(likePayload),
    db
      .collection('profiles')
      .doc(profile.uid)
      .collection('posts')
      .doc(post.id)
      .collection('likes')
      .doc(profile.uid)
      .set(likePayload),
    db
      .collection('profiles')
      .doc(profile.uid)
      .collection('engagement')
      .doc(post.uid)
      .collection('likes')
      .doc(post.id)
      .set({
        lastUpdatedAt: now,
        liked: likePayload.liked,
        type: 'post',
      }),
  ]);
};

// Saving a comment

export const commentPost = (profile, post, comment) => {
  const now = moment().utc().valueOf();
  const commentPayload = {
    uid: profile.uid,
    createdAt: now,
    comments: [...comment, comment],
  };
  if (comment !== '') {
    return Promise.all([
      db.collection('posts').doc(post.id).update({
        lastUpdatedAt: now,
      }),
      db
        .collection('posts')
        .doc(post.id)
        .collection('comments')
        .doc(profile.uid)
        .set(commentPayload),
      db
        .collection('profiles')
        .doc(profile.uid)
        .collection('posts')
        .doc(post.id)
        .collection('comments')
        .doc(profile.uid)
        .set(commentPayload),
      db
        .collection('profiles')
        .doc(profile.uid)
        .collection('engagement')
        .doc(post.uid)
        .collection('comments')
        .doc(post.id)
        .set({
          lastUpdatedAt: now,
          comment: commentPayload.comment,
          type: 'post',
        }),
    ]);
  }
  return Promise.all([
    db.collection('posts').doc(post.id).update({
      lastUpdatedAt: now,
    }),
    db.collection('posts').doc(post.id).collection('comments').doc(profile.uid).set(commentPayload),
    db
      .collection('profiles')
      .doc(profile.uid)
      .collection('posts')
      .doc(post.id)
      .collection('comments')
      .doc(profile.uid)
      .set(commentPayload),
    db
      .collection('profiles')
      .doc(profile.uid)
      .collection('engagement')
      .doc(post.uid)
      .collection('comments')
      .doc(post.id)
      .set({
        lastUpdatedAt: now,
        comment: commentPayload.comment,
        type: 'post',
      }),
  ]);
};
