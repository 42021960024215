import './Charts.css';

import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import { Container, Row, Col} from 'react-bootstrap';

import Sidebar from "../../components/sidebar/Sidebar";
import {Header} from "../../components/header/Header";
import {removeStockFromWatchlist, startStreamingStock, stockStream} from "../../api/stocks.api";
import {getStockChangePercentage, getStockPrice} from "../../helpers/StockDataParsing";
import Chart from "../../components/chart/Chart";
import Watchlist from "../../components/watchlist/Watchlist";
import { CandlestickChart } from '../../components/candlestick-chart/CandlestickChart';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getColumnItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '10px',
  border: '2px solid black',
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getRowItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: '10px',
  border: '2px solid black',
  borderRadius: '10px',
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getRowStyle = isDraggingOver => ({
  background: isDraggingOver ? "#cccccc" : "transparent",
  padding: '10px',
  margin: '20px 0px',
  width: '100%'
});

const getColumnStyle = isDraggingOver => ({
  background: isDraggingOver ? "#cccccc" : "#transparent",
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
});

function Charts() {

  const [stocks, setStocks] = useState([]);
  const [stocksPreviousDayData, setStocksPreviousDayData] = useState({});
  const [stocksLiveData, setStocksLiveData] = useState({});
  const [dashboardLayout, setDashboardLayout] = useState([{
    index: 0,
    widgets: [{
      className: 'candlestick-chart-container',
      component: 'candlestick-chart',
      index: 0
    }, {
      className: 'watchlist-container',
      component: 'watchlist',
      index: 1
    }]
  }])

  useEffect(() => {

    firebase.firestore().collection("watchlist").onSnapshot((querySnapshot) => {
      const previousDayData = {};
      var stocksFromDatabase = [];
      var loadStockInfoPromises = [];
      // for each stock from database
      querySnapshot.forEach((doc) => {
        let stock = doc.data();
        startStreamingStock(stock);
        // push the request into array
        loadStockInfoPromises.push(
            Promise.resolve(stocksFromDatabase.push(stock))
        );
      });
      // after all data is loaded, setStocks for UI to access
      return Promise.all(loadStockInfoPromises).then(() => {
        setStocks(stocksFromDatabase);
        return Promise.all(stocksFromDatabase.map((stock) => {
          return firebase.firestore().collection('endOfDay').doc(stock.ticker).get().then((previousDaySnapshot) => {
            let endOfDay = previousDaySnapshot.data();
            previousDayData[stock.ticker] = endOfDay.prevDay;
          })
        })).then(() => {
          setStocksPreviousDayData(previousDayData);
        })
      })
    });

    stockStream.subscribe(updatedStocksData => {
      setStocksLiveData({...updatedStocksData})
    });

    return () => {
      setStocks([]);
      setStocksLiveData({});
    }

  }, []);

  const getComponent = (name) => {
    if (name === 'tickers') {
      return <Row className="ticker-row">
        <Col className="border">
          SPY
          {getStockPrice(stocksLiveData, 'SPY')}
          {getStockChangePercentage(stocksPreviousDayData, stocksLiveData, 'SPY')}
        </Col>
        <Col className="border">
          QQQ
          {getStockPrice(stocksLiveData, 'QQQ')}
          {getStockChangePercentage(stocksPreviousDayData, stocksLiveData, 'QQQ')}
        </Col>
        <Col className="border">
          DIA
          {getStockPrice(stocksLiveData, 'DIA')}
          {getStockChangePercentage(stocksPreviousDayData, stocksLiveData, 'DIA')}
        </Col>
        <Col className="border">
          Select Ticker
          <span className="price">$50</span>
          <span className="percent-change">-5%</span>
        </Col>
        <Col className="border">
          Select Ticker
          <span className="price">$50</span>
          <span className="percent-change">-5%</span>
        </Col>
        <Col className="border">
          Select Ticker
          <span className="price">$50</span>
          <span className="percent-change">-5%</span>
        </Col>
      </Row>
    } else if (name === 'candlestick-chart') {
      return <CandlestickChart id="dashboard-candlestick-chart"></CandlestickChart>
    } else if (name === 'watchlist') {
      return <Watchlist previousDayData={stocksPreviousDayData} stocksLiveData={stocksLiveData} stocks={stocks}></Watchlist>
    }
  }

  let onRowDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reOrderedRows = reorder(
      dashboardLayout,
      result.source.index,
      result.destination.index
    );
    console.log('reOrderedRows = ', reOrderedRows)
    setDashboardLayout(reOrderedRows);
  }

  let onColumnDragEnd = (result, dashboardRowIndex) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newDashboardData = dashboardLayout;
    const reOrderedWidgets = reorder(
        dashboardLayout[dashboardRowIndex].widgets,
        result.source.index,
        result.destination.index
    );
    console.log('reOrderedWidgets = ', reOrderedWidgets);
    newDashboardData[dashboardRowIndex].widgets = reOrderedWidgets;
    setDashboardLayout(newDashboardData);
  }

  return (
    <div className="Dashboard">
      <Row className="m-0">
        <Sidebar />

        <Col className="main-col p-0">
          <Header></Header>

          <div className="pb-5 px-3">
            <Container className="px-0" style={{maxWidth: '100%'}}>
              {getComponent('tickers')}
              <Row>
                <Col className="p-0" style={{maxWidth: '1000px'}}>
                  {getComponent('candlestick-chart')}
                </Col>
                <Col className="p-0" style={{maxWidth: '25%'}}>
                  {getComponent('watchlist')}
                </Col>
              </Row>
              {/*<DragDropContext onDragEnd={onRowDragEnd}>*/}
              {/*  <Droppable droppableId="droppable">*/}
              {/*    {(provided, snapshot) => (*/}
              {/*      <div*/}
              {/*          {...provided.droppableProps}*/}
              {/*          ref={provided.innerRef}*/}
              {/*          style={getRowStyle(snapshot.isDraggingOver)}*/}
              {/*      >*/}
              {/*        {dashboardLayout.map((dashboardRow, rowIndex) => (*/}
              {/*          <div key={'dashboard-row-' + dashboardRow.index}>*/}
              {/*            <Draggable draggableId={'dashboard-row-' + dashboardRow.index} index={rowIndex}>*/}
              {/*              {(provided, snapshot) => (*/}
              {/*                  <div*/}
              {/*                      ref={provided.innerRef}*/}
              {/*                      {...provided.draggableProps}*/}
              {/*                      {...provided.dragHandleProps}*/}
              {/*                      style={getRowItemStyle(*/}
              {/*                          snapshot.isDragging,*/}
              {/*                          provided.draggableProps.style*/}
              {/*                      )}*/}
              {/*                  >*/}
              {/*                    <div className="position-relative">*/}
              {/*                      <MdOutlineClose className="close-icon" onClick={(e) => {*/}
              {/*                        e.stopPropagation();*/}
              {/*                      }} />*/}
              {/*                      {dashboardRow.index}*/}


              {/*                    </div>*/}
              {/*                  </div>*/}
              {/*              )}*/}
              {/*            </Draggable>*/}

              {/*            <DragDropContext onDragEnd={(dropResult) => onColumnDragEnd(dropResult, rowIndex)}>*/}
              {/*              <Droppable droppableId="droppable">*/}
              {/*                {(provided, snapshot) => (*/}
              {/*                  <div {...provided.droppableProps} ref={provided.innerRef} style={getColumnStyle(snapshot.isDraggingOver)} className="widget-container">*/}
              {/*                    {dashboardRow.widgets.map((widget, index) => (*/}
              {/*                      <span key={'dashboard-column-' + widget.index} className={'widget ' + widget.className}>*/}
              {/*                        <Draggable draggableId={'dashboard-row-' + widget.index} index={index}>*/}
              {/*                        {(provided, snapshot) => (*/}
              {/*                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}*/}
              {/*                            style={getColumnItemStyle(*/}
              {/*                              snapshot.isDragging,*/}
              {/*                              provided.draggableProps.style*/}
              {/*                            )}*/}
              {/*                          >*/}
              {/*                            <div className="position-relative">*/}
              {/*                              <MdOutlineClose className="close-icon" onClick={(e) => {*/}
              {/*                              e.stopPropagation();*/}
              {/*                            }} />*/}
              {/*                              {widget.component}*/}
              {/*                            </div>*/}
              {/*                          </div>*/}
              {/*                        )}*/}
              {/*                        </Draggable>*/}

              {/*                        {getComponent(widget.component)}*/}
              {/*                      </span>*/}
              {/*                    ))}*/}
              {/*                  </div>*/}
              {/*                )}*/}
              {/*              </Droppable>*/}
              {/*            </DragDropContext>*/}
              {/*          </div>*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </Droppable>*/}
              {/*</DragDropContext>*/}
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Charts;
