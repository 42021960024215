import {useEffect, useState} from "react";
import {Modal, Button, Image} from "react-bootstrap";
import "./NewsFeed.css"

import {
    getStockChangePercentage,
    getStockPrice,
    getStockDelta,
    getStockDeltaAsFloat
} from "../../helpers/StockDataParsing";

let isTest = window.location.hostname.includes('localhost');
const testEndpoint = 'http://localhost:8080'
const prodEndpoint = 'https://automated-stock-trading.uc.r.appspot.com'

function NewsFeed(props) {

    const [news, setNews] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedNews, setSelectedNews] = useState({})

    useEffect(() => {
        let newsURL = (isTest ? testEndpoint : prodEndpoint) +  '/getNews';
        if (props.ticker) {
            newsURL = `https://api.polygon.io/v2/reference/news?news=${props.ticker}&apiKey=XUsus_BzCa1dgsHo1bsweyv5mK_Wl1py`
        }

        // fetch(newsURL).then((data) => {
        //     return data.json()
        // }).then((newsData) => {
        //     if (newsData && newsData.results) {
        //         return setNews(newsData.results);
        //     }
        //     return setNews(newsData);
        // })
    }, [])

    return <div>
            {
                news.map((data, index) => {
                    return (
                        <div className="my-3" key={'news-link-' + index} onClick={() => { setShow(true); setSelectedNews(data) }}>
                            <Image src={data?.publisher?.favicon_url} width="30"></Image>
                            <strong> {data.publisher.name} -</strong> <em>{data.published_utc}</em>
                            <p>{data.title}</p>
                        </div>
                    )
                })
            }

        <Modal show={show} onHide={() => setShow(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedNews.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Image src= {selectedNews?.publisher?.favicon_url}></Image>
                <strong> {selectedNews?.publisher?.name}</strong> <em>{selectedNews.published_utc}</em>
                <div className="text-center">
                    {selectedNews.image_url && <Image src={selectedNews.image_url} height={100}></Image>}
                </div>
                <p><a href={selectedNews.article_url}>{selectedNews.title}</a> <em>(Click for full article)</em></p>
                {selectedNews.description}
                <strong><p className="mb-1 mt-2">Related Tickers:</p></strong>
                {selectedNews && selectedNews.tickers && selectedNews.tickers.map((ticker) => {
                    return (<p className="mb-1">{ticker} </p>)
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
}

export default NewsFeed;
