import './Landing.scss';

import { useEffect, useState } from 'react';

import { Button, Card, Container, Form, Nav, Navbar } from 'react-bootstrap';
import AuthModal from '../../components/auth-modal/AuthModal';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import logo from "../../assets/svgs/logo.svg";
import hero from "../../assets/svgs/hero.svg";
import FeatureCard from "../../components/feature-card/FeatureCard.component";
import marketImage from '../../assets/images/market.png'
import chartImage from '../../assets/images/chart.png'
import watchlistImage from '../../assets/images/watchlist.png';
import collabImage from '../../assets/images/collab.png';
import traderImage from '../../assets/images/trader.png';
import { AiFillCloseSquare, AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useRef } from "react";
import clsx from "clsx";
import Hide from "../../molecules/Hide.mole";
import Slider, { SlideContext } from "../../components/slider/Slider.component";
import { useContext } from "react";

const navData = [
  {
    label: "Home",
    path: "#home"
  },
  {
    label: "Market",
    path: "#market"
  },
  {
    label: "Feature",
    path: "#feature"
  },
  {
    label: "Collaboration Chat",
    path: "#collaboration-chat"
  },
  {
    label: "Trade Portfolio",
    path: "#trade-portfolio"
  }
];

function Header() {

  const headerRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = open ? "hidden" : "auto";
    document.body.style.overflow = open ? "hidden" : "auto";
  }, [open]);

  useEffect(() => {

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
  }, []);

  const handleScroll = (event) => {
    const element = headerRef.current;
    const scrolled = window.pageYOffset;
    const height = element.clientHeight;

    if (scrolled > height) {
      element.classList.add("sticky");
    } else {
      element.classList.remove("sticky");
    }
  }

  return (
    <div className="w-100 z-99" ref={headerRef}>
      <header className="d-flex justify-content-between align-items-center py-4 container" >
        <div className="d-flex align-items-center position-relative z-99">
          <img src={logo} />
          <h5 className={clsx("mb-0 ms-2", open && "text-white")}>SentiTrade</h5>
        </div>
        <div onClick={() => setOpen(pre => !pre)} className={clsx("text-2xl d-lg-none z-99 position-relative opacity-50:active cursor-pointer", open && "text-white")}>
          <Hide open={!open}
            fallback={<AiOutlineClose />}
          >
            <AiOutlineMenu />
          </Hide>
        </div>

        <Navbar className={clsx("d-lg-block d-none ", open && "mobile-nav")} style={{ zIndex: 56 }}>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Nav className="align-items-center">
            {navData.map(item => (
              <Nav.Link onClick={() => setOpen(false)} href={`${item.path}`} >
                {item.label}
              </Nav.Link>
            ))}
            <Nav.Link>
                <AuthModal linkText="Login" />
            </Nav.Link>
          </Nav>
        </Navbar>
      </header>
    </div>
  )
}

function SliderIndicator() {

  const { state, setState } = useContext(SlideContext);
  const { view, count, index } = state;

  const handleClick = (index) => {
    setState(pre => ({
      ...pre,
      index: index
    }))
  }

  if (count === view) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center mt-5">
      {Array(count).fill().map((_, i) => (
        <button
          style={{
            width: i === index ? 48 : 16,
            height: 16,
            background: i === index ? "#4752FF" : "#37395d"
          }}
          onClick={() => handleClick(i)}
          className="rounded-pill mx-1 border-0 transition-300 opacity-50:active"
        />
      ))}
    </div>
  )
}

function Landing() {

  const [email, setEmail] = useState('');

  function validateEmail(email) {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      return true;
    } else {
      return false;
    }
  }

  function addUserToMailingList() {
    const email = document.getElementById('waitlist-email').value;
    alert('email = ' + email);
    if (validateEmail(email)) {
      return firebase.firestore().collection('mailingList').add({
        email: email
      }).then(() => {
        alert("You've been added to the waitlist.");
      })
    }
    alert("Please enter a valid email");
  }


  return (


    <main>
      <section className="bg-dot min-vh-100 position-relative d-flex flex-column" id="home">
        <Header />
        <div className="d-flex flex-lg-row flex-column  mt-0 mt-lg-5 pt-3 flex-1 ms-20 ">
          <div className="flex-1 pt-5 my-5 mt-0 mt-lg-5">
            <div className="container-lg-half container ms-auto me-lg-0 ps-2">
              <h1 className="text-6xl text-2xl lg:text-6xl lh-sm">
                Designed by traders
                for traders SentiTrade
                handles it all.
              </h1>
              <h5 className="text-muted lh-base fw-normal mt-4">
                SentiTrade is an innovative platform for stock traders. SentiTrade condenses all information from financial data to news into one single platform. No more need for multiple platforms anymore, SentiTrade handles it all.
              </h5>
              <div className="d-flex mt-5 border border-dark border-2 w-100 p-2 rounded">
                <input className="border-0 outline-none flex-1 w-inherit" id="waitlist-email" type="email" placeholder="Enter Your Email" name="email" />
                <Button size="lg" className="m-0 px-4" onClick={() => addUserToMailingList()}>Request</Button>
              </div>
            </div>
          </div>
          <div className="flex-1 align-self-end" >
            {/* <div className="w-100"> */}
            <img className="w-100 opacity-1" src={hero} alt="" />
            {/* </div> */}
          </div>
        </div>
      </section>

      <section className="bg-blue-deep min-vh-100 d-flex align-items-center" id="feature">
        <div className="container py-5">
          <div className="pt-5 text-center">
            <h1 className="text-white">Feature</h1>
            <p className="text-gray-light w-lg-50 mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
            </p>
          </div>
          <div className="d-flex gap-5 mt-5 pt-5">
            <Slider view={3} breakpoints={{ 600: 1, 800: 2 }}>
              <Slider.Container>
                <Slider.Item>
                  <FeatureCard
                    title="Market"
                    description=""
                    image={marketImage}
                  />
                </Slider.Item>
                <Slider.Item>
                  <FeatureCard
                    title="Chart"
                    description=""
                    image={chartImage}
                  />
                </Slider.Item>
                <Slider.Item>
                  <FeatureCard
                    title="Watchlist"
                    description=""
                    image={watchlistImage}
                  />
                </Slider.Item>
              </Slider.Container>
              <Slider.Action>
                <SliderIndicator />
              </Slider.Action>
            </Slider>
          </div>
        </div>
      </section>


      <section className="py-5 bg-square" id="collaboration-chat">
        <div className="container d-flex flex-lg-row flex-column-reverse align-items-center py-5">
          <div className="flex-1 d-flex flex-column justify-content-center text-center text-lg-start mt-5 mt-lg-0">
            <h1 className="text-5xl">Collaboration Chat.</h1>
            <p className="w-lg-75 text-gray-base mx-auto mx-lg-0 border-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className="flex-1 text-center">
            <img className="w-100 w-sm-auto" src={collabImage} alt="" />
          </div>
        </div>
      </section>

      <section className="py-5 bg-square bg-blue-deep" id="trade-portfolio">
        <div className="container d-flex text-center text-lg-start flex-column flex-lg-row py-5">
          <div className="flex-1 text-center pt-5 mt-4">
            <img className="w-100 w-sm-auto" src={traderImage} alt="" />
          </div>
          <div className="flex-1 d-flex flex-column justify-content-center">
            <h1 className="text-5xl text-white">Trader Profile.</h1>
            <p className="w-lg-75 text-gray-light mx-auto mx-lg-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </section>

      <footer className="bg-blue-light">
        <div className="px-5 mx-5 py-4 d-flex align-items-center justify-content-center justify-content-md-between">

          <div className="">
            <div className="d-flex align-items-center">
              <img src={logo} />
              <h5 className="mb-0 ms-2 text-white">SentiTrade</h5>
            </div>
          </div>
          <div className="d-none d-md-block">
            <Navbar >
              <Nav className="align-items-center text-white">
                <Nav.Link href='#' className="text-white opacity-50">Market</Nav.Link>
                <Nav.Link href='#' className="text-white opacity-50">Feature</Nav.Link>
                <Nav.Link href='#' className="text-white opacity-50">Collaboration Chat</Nav.Link>
                <Nav.Link href='#' className="text-white opacity-50">Trade Portfolio</Nav.Link>
              </Nav>
            </Navbar>
          </div>

        </div>
        <div style={{ height: 4, background: '#4752FF' }}></div>
      </footer>

    </main>

  );
}

export default Landing;
