import { onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

function useOnSnapshot(fn,path,defaultState,queryfn) {
    const [state,setState] = useState(defaultState);

    
    useEffect(()=>{
        
        const isValid = path.every(item=>Boolean(item));
        
        if (!isValid) {
            return;
        }
        
        const ref = fn(db,...path);
        const query = typeof queryfn === "function" ? queryfn(ref) : ref;
        
        const unsubscribe = onSnapshot(query,(snapshot)=>{
            if (query.type === "collection" || query.type === "query") {
                const data = [];

                snapshot.forEach(doc=>data.push(doc.data()));
                setState(data)

            }else if (query.type === "document") {
                setState(snapshot.data())
            }
        })

        return ()=> unsubscribe();


    },path)

    return state;
}

export default useOnSnapshot;