import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import Sidebar from '../../components/sidebar/Sidebar';
import { db } from '../../firebase';
import Hide from '../../molecules/Hide.mole';
import firebase from 'firebase/compat/app';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';
import { async } from 'rxjs';

function FriendsListItem({ id, status, name }) {
  const [profile, setProfile] = useState(null);
  const user = firebase.auth().currentUser;
  const { userId, displayName } = user;

  useEffect(() => {
    const docRef = doc(db, 'profiles', id);

    getDoc(docRef)
      .then((snapshot) => {
        setProfile(snapshot.data());
      })
      .catch((e) => console.log(e));
  }, []);

  const acceptFriendRequest = () => {
    const userRef = doc(db, 'profiles', userId, 'friends', id);
    const friendRef = doc(db, 'profiles', id, 'friends', userId);

    Promise.all([
      setDoc(userRef, {
        name,
        id: id,
        status: 'accepted',
      }),
      setDoc(friendRef, {
        name: displayName,
        id: userId,
        status: 'accepted',
      }),
    ]);
  };

  const removeFriend = async () => {
    const userRef = doc(db, 'profiles', userId, 'friends', id);
    const friendRef = doc(db, 'profiles', id, 'friends', userId);
    Promise.all([deleteDoc(userRef), deleteDoc(friendRef)]);
  };

  if (profile === undefined) {
    return null;
  }

  return (
    <ListGroup.Item className='d-flex justify-content-between align-items-center text-white pt-4'>
      <h6>{profile?.name}</h6>

      <Hide open={status === 'sent'}>
        <Button disabled>Friend Request Sent</Button>
      </Hide>
      <Hide open={status === 'pending'}>
        <Button onClick={acceptFriendRequest}>Accept Friend Request</Button>
      </Hide>
      <Hide open={status === 'accepted'}>
        <Button onClick={removeFriend}>Remove Friend</Button>
      </Hide>
    </ListGroup.Item>
  );
}

function Friends({ profile }) {
  const friends = useOnSnapshot(collection, ['profiles', profile?.uid, 'friends'], []);

  return (
    <div>
      <Row className='m-0 ' style={{ height: '100vh' }}>
        <Sidebar />
        <Col className='bg-black'>
          <ListGroup>
            <Hide open={friends.length > 0} fallback={<h1>You Don't have any friends.</h1>}>
              {friends.map((friend) => (
                <FriendsListItem
                  key={friend.id}
                  id={friend.id}
                  status={friend.status}
                  name={friend.name}
                />
              ))}
            </Hide>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}

export default Friends;
