import React from "react";

function truncateToDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
}

export function getStockPrice(stocks, ticker) {
    return <span className="price">${stocks[ticker]?.c.toFixed(2)}</span>
}

export function getStockChangePercentage(previousDayStocks, stocks, ticker) {
    if (!stocks[ticker] || !previousDayStocks[ticker]?.c) {
        return 'loading...';
    }
    const percentageChanged = ((stocks[ticker]?.c - previousDayStocks[ticker]?.c) / previousDayStocks[ticker]?.c) * 100;
    return <span className={'percent-change ' + (percentageChanged > 0 ? 'text-success' : 'text-danger')}>{percentageChanged.toFixed(2)}%</span>
}

export function getStockDelta(previousDayStocks, stocks, ticker) {
    const stockDelta = (stocks[ticker]?.c - previousDayStocks[ticker]?.c);
    return <span className={'stock-delta ' + (stockDelta > 0 ? 'text-success' : 'text-danger')}>${stockDelta.toFixed(2)}</span>
}

export function getStockDeltaAsFloat(previousDayStocks, stocks, ticker) {
    const stockDelta = (stocks[ticker]?.c - previousDayStocks[ticker]?.c);
    return stockDelta;
}

export function getMarketMoversDelta(stock) {
    const stockDelta = stock.todaysChange;
    return <span className={'stock-delta ' + (stockDelta > 0 ? 'text-success' : 'text-danger')}>${stockDelta.toFixed(2)}</span>
}

export function getMarketMoverChangePercentage(stock) {
    const percentageChanged = stock.todaysChangePerc;
    return <span className={'percent-change ' + (percentageChanged > 0 ? 'text-success' : 'text-danger')}>{percentageChanged.toFixed(2)}%</span>
}
