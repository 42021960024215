//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import { FiHome, FiLogOut, FiUsers } from "react-icons/fi";
import {RiFilePaper2Line, RiProfileFill} from "react-icons/ri";
import {BsChatLeftDots, BsFilePost} from "react-icons/bs";
import {BiLineChart} from "react-icons/bi";
import { FaCog } from "react-icons/fa";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";

import "./Sidebar.css";
import logo from '../../Logo_Icon.png';
import {Link, useLocation} from "react-router-dom";
import firebase from "firebase/compat/app";

const Sidebar = () => {
    const { pathname } = useLocation();


    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    return (
        <>
            <div id="sidebar">
                {/* collapsed props to change menu size using menucollapse state */}
                <ProSidebar collapsed={menuCollapse}>
                <SidebarContent>
                    <div className="logotext mt-3">
                        {/* small and big change using menucollapse state */}
                        {menuCollapse ? "Logo" : (
                            <img src={logo} className="logo" />
                        )}
                        <span className="logo-text">SentiTrade</span>
                    </div>


                        <Menu iconShape="square">
                            <MenuItem active={pathname.includes('profile')} icon={<Link to="/profile"><RiProfileFill /></Link>}>Profile</MenuItem>
                            <MenuItem active={pathname.includes('dashboard')} icon={<Link to="/dashboard"><FiHome /></Link>}>Dashboard</MenuItem>
                            <MenuItem active={pathname.includes('chat')} icon={<Link to="/chat"><BsChatLeftDots /></Link>}>Chat</MenuItem>
                            <MenuItem active={pathname.includes('posts')} icon={<Link to="/posts"><BsFilePost /></Link>}>Posts</MenuItem>
                            <MenuItem active={pathname.includes('charts')} icon={<Link to="/charts"><BiLineChart /></Link>}>Charts</MenuItem>
                            <MenuItem active={pathname.includes('options')} icon={<Link to="/options"><RiFilePaper2Line /></Link>}>Options</MenuItem>
                            <MenuItem active={pathname.includes('friends')} icon={<Link to="/friends"><FiUsers/></Link>}>Friends</MenuItem>
                            <MenuItem active={pathname.includes('settings')} icon={<Link to="/settings"><FaCog /></Link>}>Settings</MenuItem>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem icon={<FiLogOut />} onClick={() => firebase.auth().signOut()}>Logout</MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </>
    );
};

export default Sidebar;
