import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { FaHashtag } from 'react-icons/fa';
import { BiMessageDetail } from 'react-icons/bi';
import moment from 'moment';

import './Chat.scss';
import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import Messenger from '../messenger/Messenger.component';
import useWidth from '../../hooks/useWidth.hook';
import clsx from 'clsx';
import Hide from '../../molecules/Hide.mole';
import { doc, getDoc, deleteDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase/index';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';

let currentChatRoomSubscription = null;

function FriendView({ id }) {
  const friend = useOnSnapshot(doc, ['profiles', id], null);

  if (!friend) {
    return null;
  }

  return (
    <div className='text-start d-flex align-items-center'>
      <div className='profile-image profile-active small'>
        <img className='user-img' src={friend.profileImage} alt='ar' />
      </div>
      <h6>{friend.name}</h6>
    </div>
  );
}

function CreateNewRoom() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <ListGroup.Item onClick={handleShow} className='cursor-pointer'>
        + Add Chat Room
      </ListGroup.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Chat Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label className=''>Room Name</Form.Label>
            <Form.Control placeholder='Give chat room a name...' />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default function Chat({ profile }) {
  const [chatRooms, setChatRooms] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [selectedChatRoomMessages, setSelectedChatRoomMessages] = useState([]);
  const [chatUserProfiles, setChatUserProfiles] = useState({});
  const chatBoxRef = useRef(null);
  const [width, elementRef, isMobile] = useWidth(400);
  const [sidebarOpen, setSidebarOpen] = useState(isMobile);
  const userId = firebase.auth()?.currentUser?.uid;
  const friends = useOnSnapshot(collection, ['profiles', userId, 'friends'], []);

  useEffect(() => {
    firebase
      .firestore()
      .collection('chatRooms')
      .onSnapshot((snapshot) => {
        let rooms = [];
        snapshot.docs.forEach((doc) => {
          rooms.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setChatRooms(rooms);
        setSelectedChatRoom(rooms[0]);
        subscribeToChatRoomMessages(rooms[0]);
      });
  }, []);

  const subscribeToChatRoomMessages = (chatRoom) => {
    if (currentChatRoomSubscription) {
      currentChatRoomSubscription();
    }
    currentChatRoomSubscription = firebase
      .firestore()
      .collection('chatRooms')
      .doc(chatRoom.id)
      .collection('messages')
      .onSnapshot((snapshot) => {
        let messages = [];
        let chatUserProfilesToLoad = {};
        snapshot.docs.forEach((doc) => {
          let messageData = doc.data();
          if (
            messageData &&
            !chatUserProfiles[messageData.senderID] &&
            !chatUserProfilesToLoad[messageData.senderID]
          ) {
            chatUserProfilesToLoad[messageData.senderID] = true;
          }
          messages.push({ ...messageData, id: doc.id });
        });
        let loadedUserProfiles = {};
        return Promise.all(
          Object.keys(chatUserProfilesToLoad).map((userID) => {
            return firebase
              .firestore()
              .collection('profiles')
              .doc(userID)
              .get()
              .then((profile) => {
                return (loadedUserProfiles[userID] = {
                  id: userID,
                  name: profile?.data()?.name,
                  profileImage: profile?.data()?.profileImage,
                });
              });
          })
        ).then((profiles) => {
          setChatUserProfiles(loadedUserProfiles);
          setSelectedChatRoomMessages(messages);
        });
      });
  };

  const sendMessage = (message) => {
    if (!message) return;
    firebase
      .firestore()
      .collection('chatRooms')
      .doc(selectedChatRoom.id)
      .collection('messages')
      .doc(moment().utc().valueOf() + '')
      .set({
        content: message,
        senderID: firebase.auth().currentUser.uid,
        timestamp: moment().utc().valueOf(),
      });
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      const element = chatBoxRef.current;

      element.scrollTo(0, element.scrollHeight);
    }
  }, [selectedChatRoomMessages]);

  const handleRoomSelect = (room) => {
    if (isMobile) {
      setSidebarOpen(false);
    }
    setSelectedChatRoom(room);
  };

  const handleDeleteMessage = (id, sender) => {
    if (selectedChatRoom) {
      const docRef = doc(db, 'chatRooms', selectedChatRoom.id, 'messages', id);

      const userId = firebase.auth().currentUser.uid;

      if (userId === sender) {
        deleteDoc(docRef)
          .then(() => {
            console.log('Message Delete Successfuly');
          })
          .catch(() => {
            console.log('failed to delete the message!!');
          });
      }
    }
  };

  return (
    <div
      ref={elementRef}
      className={clsx(sidebarOpen && 'sidebar-open', 'd-flex h-100 chat-widget')}
    >
      <Hide open={sidebarOpen}>
        <div className={clsx(isMobile && 'flex-1', 'px-2 mt-3')}>
          <ListGroup className='chatroom-container'>
            <CreateNewRoom />
            {chatRooms.map((room, index) => {
              return (
                <ListGroup.Item key={'chatroom-' + index} onClick={() => handleRoomSelect(room)}>
                  {room?.name}
                </ListGroup.Item>
              );
            })}
          </ListGroup>

          <div className='mt-4'>
            <h5>Friends</h5>
            <ListGroup>
              {friends.map((friend) => (
                <ListGroup.Item key={friend.id}>
                  <FriendView id={friend.id} />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      </Hide>
      <Hide open={isMobile ? !sidebarOpen : true}>
        <div className='flex-1 d-flex flex-column messenger'>
          <Hide open={!sidebarOpen && isMobile}>
            <Button className='back-btn' onClick={() => setSidebarOpen(true)}>
              Go Back
            </Button>
          </Hide>
          <header
            style={{ boxShadow: '-5px -1px3px 0px #40444b' }}
            className='header-container d-flex justify-content-between px-4 py-2'
          >
            <div>
              <FaHashtag />
              <span className='ms-2'>{selectedChatRoom?.name}</span>
            </div>

            <span>
              <BiMessageDetail />
            </span>
          </header>
          <div
            ref={chatBoxRef}
            className='overflow-auto ms-2'
            style={{ flexGrow: 1, flexBasis: 0, scrollBehavior: 'smooth' }}
          >
            {selectedChatRoomMessages.map((message, index) => (
              <Messenger.View
                message={message}
                profiles={chatUserProfiles}
                key={'messages-' + index}
                onDelete={handleDeleteMessage}
                profile={profile}
              />
            ))}
          </div>

          <Messenger.Sent onSent={sendMessage} profiles={chatUserProfiles} />
        </div>
      </Hide>
    </div>
  );
}
