import JoditEditor from 'jodit-react';
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Hide from '../../molecules/Hide.mole';
import MentionPanel from '../mention-panel/MentionPanel.component';
import Post from "../post/Post.component";

// Fetch Data of Profiles
const profilesData = {
    "8CU2pVfKUabAeM7GiAXJhYblFD52": {
        "id": "8CU2pVfKUabAeM7GiAXJhYblFD52",
        "name": "ImranSheikh",
        "profileImage": "https://firebasestorage.googleapis.com/v0/b/automated-stock-trading.appspot.com/o/profiles%2F8CU2pVfKUabAeM7GiAXJhYblFD52?alt=media&token=48eecae0-872d-4541-9174-78d24020842f"
    },
    "Hrqfe0D4GEOlG3m4grclHKw8PmD2": {
        "id": "Hrqfe0D4GEOlG3m4grclHKw8PmD2",
        "name": "Hunter Trawick",
        "profileImage": "https://firebasestorage.googleapis.com/v0/b/automated-stock-trading.appspot.com/o/profiles%2FHrqfe0D4GEOlG3m4grclHKw8PmD2.jpg?alt=media&token=f432b6d0-c081-4709-9938-de244eacf51b"
    },
    "kBvHGE0B9cgALd9qzMPYaJODs792": {
        "id": "kBvHGE0B9cgALd9qzMPYaJODs792",
        "name": "Jimmy Kim",
        "profileImage": "https://firebasestorage.googleapis.com/v0/b/automated-stock-trading.appspot.com/o/profiles%2FkBvHGE0B9cgALd9qzMPYaJODs792.jpg?alt=media&token=55382de5-6f98-4844-9c94-a6cc074f3980"
    },
    "BkLccyT9rHaZnNaMJgfSG2R18cw2": {
        "id": "BkLccyT9rHaZnNaMJgfSG2R18cw2",
        "name": "Asaduzzaman Himel",
        "profileImage": "https://firebasestorage.googleapis.com/v0/b/automated-stock-trading.appspot.com/o/profiles%2FBkLccyT9rHaZnNaMJgfSG2R18cw2.jpg?alt=media&token=e43b9643-759f-45c4-a653-2cc546935427"
    },
    "oUIAVzZgKBUXFHC08usa8TNe7Vz1": {
        "id": "oUIAVzZgKBUXFHC08usa8TNe7Vz1",
        "name": "Annm"
    }
}



function PostEditor({ createPost, profile, button, btnTitle, title, preview,children }) {
    const [show, setShow] = useState(false);

    const editor = useRef(null);
    const jodit = useRef(null);
    const [content, setContent] = useState("Hello");
    const [profiles, setProfiles] = useState(profilesData);
    const [openMention, setOpenMention] = useState(false);
    const [listenerRef, setListenerRef] = useState(null);
    const atRef = useRef(null)


    const handleClose = () => {
        setShow(false)
        // onChange(null)
    };
    const handleShow = () => {
        setShow(true)
    };


    const postPreview = () => {

        let postPayload = {};

        if (profile) {
            const now = moment().utc().valueOf();
            const postID = profile.uid + '-' + now;
            postPayload = {
                html: content,
                createdAt: now,
                uid: profile.uid,
                id: postID
            }
        }
        return postPayload
    }


    useEffect(() => {

        listenerRef && listenerRef.addEventListener("keydown", handleKeydown);

        return () => listenerRef && listenerRef.removeEventListener("keydown", handleKeydown)

    }, [listenerRef])

    const config = useMemo(() => {
        return {
            removeButtons: ["source"],
            readonly: false,
            placeholder: 'Start typings...'
        }
    }, []);

    const handlePublish = () => {
        console.log(content);
        setContent("")
        createPost(content);
        handleClose();

        setTimeout(() => {
            editor.current?.focus();
        }, 100);
    }

    const handleMentionBefore = (user) => {
        // handleMention();

        if (jodit.current) {
            const jt = jodit.current;
            const element = document.createElement("span");
            element.contentEditable = "false"
            element.innerText = `@${user.name}`;
            element.className = "bg-info";

            atRef.current && atRef.current.remove();
            jt.s.insertNode(element);
            setOpenMention(false);
        }
    }

    const handleChange = (value) => {
        if (!document.body.contains(atRef.current)) {
            atRef.current = null;
            setOpenMention(false);
        }

        setOpenMention(false);
        setContent(value);

    }

    const handleKeydown = (event) => {
        if (event.key === '@') {
            event.preventDefault();

            if (jodit.current) {
                const jt = jodit.current;
                const element = document.createElement("span");
                element.contentEditable = "true"
                element.innerText = `@`;
                atRef.current = element;
                jt.s.insertNode(element);
                setOpenMention(true);
            }
        }
    }

    const handleRef = (ref) => {
        if (ref instanceof Element) {
            editor.current = ref;
        } else if (ref.isJodit) {
            jodit.current = ref;
        }
    }

    const handleContentChange = (value) => {
        setContent(value);
    }

    return (
        <React.Fragment>
            {React.cloneElement(button, { onClick: handleShow })}

            <Modal show={show} onHide={handleClose} ref={ref => ref?.dialog?.removeAttribute("tabindex")} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <div ref={setListenerRef} className="relative" >
                        <div className="">
                            <JoditEditor
                                ref={handleRef}
                                value={content}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={handleContentChange} // preferred to use only this option to update the content for performance reasons
                                onChange={handleChange}
                            />
                        </div>
                        <Hide open={openMention}>
                            <MentionPanel onMention={handleMentionBefore} profiles={profiles} />
                        </Hide>

                        {children}
                    </div>
                    <Hide open={preview}>
                        <div className="mt-4">
                            <Post profile={profile} post={postPreview()} />
                        </div>
                    </Hide>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePublish}>
                        {btnTitle}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default PostEditor
