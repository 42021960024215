import React, { useEffect } from 'react';
import { Container, Row, Col, Card, ProgressBar} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import firebase from "firebase/compat/app";
import moment from "moment";
import TickerTape from "../../components/ticker-tape/TickerTape";
import {CandlestickChart} from "../../components/candlestick-chart/CandlestickChart";
import Watchlist from "../../components/watchlist/Watchlist";
import MarketMovers from "../../components/market-movers/MarketMovers";
import Chat from "../../components/chat/Chat";
import NewsFeed from "../../components/news-feed/NewsFeed";
import Sidebar from "../../components/sidebar/Sidebar";

function ViewStock({profile}) {
  const {ticker} = useParams();

  useEffect(async () => {
    // TODO: load data from the database
      const companyDetails = await firebase.firestore().collection('companyDetails').doc(ticker).get()
      let financialsCurrentYear = await firebase.firestore().collection('financials').doc(ticker).collection(moment().format('YYYY')).get();
      let financials = {};
      financialsCurrentYear.docs.forEach((doc) => {
          let report = doc.data();
          financials[report.fiscal_year] = {};
          financials[report.fiscal_year][report.fiscal_period] = report;
      })
      console.log('companyDetails = ', companyDetails.data());
      console.log('financials = ', financials);
  }, []);

    const getComponent = (name) => {
        if (name === 'candlestick-chart') {
            return (
                // <Chart id="dashboard-candlestick-chart" />
                <CandlestickChart id={'dashboard-candlestick-chart-tv-' + ticker} ticker={ticker}></CandlestickChart>
            )
        } else if (name === 'chat') {
            return (
                <Chat profile={profile}></Chat>
            )
        } else if (name === 'news-feed') {
            return (
                <NewsFeed ticker={ticker}></NewsFeed>
            )
        }
    }

  return (
    <div className="StockAnalysis">
        <Row className="m-0">
            <Sidebar />

            <Col className="col-3">
                {getComponent('chat')}
            </Col>
            <Col className="row">
                <Col className="col-6">
                    {getComponent('candlestick-chart')}
                </Col>
                <Col className="col-6">
                    Similar industry stocks go here
                </Col>
                <Col className="col-4">
                    Speedometer type thing
                </Col>
                <Col className="col-4">
                    Financials go here
                </Col>
                <Col className="col-4" style={{maxHeight: '400px', overflowY: 'scroll'}}>
                    {getComponent('news-feed')}
                </Col>
            </Col>
        </Row>
    </div>
  );
}

export default ViewStock;
