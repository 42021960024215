import clsx from "clsx";
import React from 'react'
import { Button } from "react-bootstrap"
import useTouch from "../../hooks/useTouch.hook";

function FeatureCard({ image, title, description }) {

    const isTouch = useTouch();

    return (
        <div className={clsx("text-center p-3 feature-card rounded pb-4",isTouch && "touch")}>
            <img src={image} alt="" className="py-5" />

            <h1 className="fw-normal text-white">{title}</h1>
            <p className="text-gray-light">
                {description}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <Button size="lg" className="mx-auto py-3 px-5">Learn More</Button>
        </div>
    )
}

export default FeatureCard