import firebase from 'firebase/compat/app';
import moment from 'moment';

let profile = {};

export const setProfileLocally = (data) => {
  profile = data;
};

export const getProfileLocally = () => {
  return profile;
};

export const loadUserDetails = (userID) => {
  let userDetails = localStorage.getItem('userDetails.' + userID);
  if (userDetails === 'loading') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return loadUserDetails(userID).then((details) => {
          resolve(details);
        });
      }, 500);
    });
  } else if (userDetails) {
    userDetails = JSON.parse(userDetails);
    if (moment(userDetails.lastUpdated).isBefore(moment().subtract(1, 'days'))) {
      localStorage.removeItem('userDetails.' + userID);
      return loadUserDetails(userID);
    }
    return Promise.resolve(userDetails);
  } else {
    localStorage.setItem('userDetails.' + userID, 'loading');
    return firebase
      .firestore()
      .collection('profiles')
      .doc(userID)
      .get()
      .then((profile) => {
        userDetails = {
          id: userID,
          name: profile?.data()?.name,
          profileImage: profile?.data()?.profileImage,
        };
        localStorage.setItem('userDetails.' + userID, JSON.stringify(userDetails));
        return userDetails;
      });
  }
};
