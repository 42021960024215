import React, { Children, cloneElement, createContext } from 'react'
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";

export const SlideContext = createContext({});


function valueFromRange(obj, input) {

    const keys = Object.keys(obj).map(item => Number(item)).sort((a, b) => a - b);

    if (keys.includes(input)) {
        return obj[input]
    }

    let start = 0;
    let value = undefined;

    for (let key of keys) {
        if (start < input && input < key) {
            value = obj[key];
            break;
        }

        start = key;
    };

    return value;
}


function Slider({ children, view = 1, breakpoints = {}, }) {

    const ref = useRef(null);
    const [state, setState] = useState({
        width: null,
        view: view,
        count: null,
        index: 0
    });

    const handleResize = () => {
        if (ref.current) {
            setState(pre => ({
                ...pre,
                width: ref.current.clientWidth,
            }))
        }
    }

    useEffect(() => {
        if (state.width > 0) {
            setState(pre => ({
                ...pre,
                view: valueFromRange(breakpoints, state.width) || view
            }))
        }
    }, [state.width]);

    useEffect(() => {
        if (ref.current) {
            setState(pre => ({
                ...pre,
                width: ref.current.clientWidth,
            }))
        }

        window.addEventListener("resize", handleResize);

        return ()=>window.removeEventListener("resize", handleResize);

    }, []);

    return (
        <SlideContext.Provider value={{ state, setState }}>
            <div ref={ref} className="w-100">
                {children}
            </div>
        </SlideContext.Provider>
    )
}

function SliderContainer({ children }) {

    const { setState } = useContext(SlideContext);

    useEffect(() => {
        setState(pre => ({
            ...pre,
            count: Children.count(children)
        }));
    }, [])

    return (
        <div className="d-flex overflow-hidden">
            {Children.map(children, (child, i) => cloneElement(child, { slideIndex: i }))}
        </div>
    )
}


function SliderItem({ children, slideIndex }) {

    const [style, setStyle] = useState({});
    const { state } = useContext(SlideContext);
    const { width, view } = state;


    useEffect(() => {

    }, [state.index]);

    useEffect(() => {
        if (width !== null && view > 0) {
            const itemWidth = width / view;
            setStyle({
                width: itemWidth,
                minWidth: itemWidth,
                transform: `translateX(${state.index * itemWidth * -1}px)`,
                transition: "all 300ms"
            })
        }
    }, [state])

    return (
        <div className="" style={style}>
            {children}
        </div>
    )
}

export const SliderActionContext = createContext({});

function SliderActionLeft() {

    const { left } = useContext(SliderActionContext);

    return (
        <button onClick={left}>Left</button>
    )
}

function SliderActionRight() {

    const { right } = useContext(SliderActionContext);

    return (
        <button onClick={right}>Right</button>
    )
}

function SliderAction({ children }) {

    const { setState } = useContext(SlideContext);

    const left = () => {
        setState(pre => ({
            ...pre,
            index: pre.index > 0 ? pre.index - 1 : pre.index
        }));
    }

    const right = () => {

        setState(pre => {
            return {
                ...pre,
                index: pre.index < pre.count - 1 ? pre.index + 1 : pre.index
            }
        });
    }

    return (
        <SliderActionContext.Provider value={{ left, right }}>
            <div className="">
                {children}
            </div>
        </SliderActionContext.Provider>
    )
}

Slider.Item = SliderItem;
Slider.Container = SliderContainer;
Slider.Action = SliderAction;
Slider.ActionLeft = SliderActionLeft;
Slider.ActionRight = SliderActionRight;

export default Slider