import './StocksDashboard.css';

import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import { Container, Row, Col, Button } from 'react-bootstrap';

import Sidebar from '../../components/sidebar/Sidebar';
import { Header } from '../../components/header/Header';
import StockCard from '../../components/stock-card/StockCard';
import {
  removeStockFromWatchlist,
  startStreamingMarketMovers,
  startStreamingStock,
  stockStream,
} from '../../api/stocks.api';
import { getStockChangePercentage, getStockPrice } from '../../helpers/StockDataParsing';
import { MdOutlineClose } from 'react-icons/md';
import Chart from '../../components/chart/Chart';
import Watchlist from '../../components/watchlist/Watchlist';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Chat from '../../components/chat/Chat';
import { CandlestickChart } from '../../components/candlestick-chart/CandlestickChart';
import TickerTape from '../../components/ticker-tape/TickerTape';
import { BsArrowsMove } from 'react-icons/bs';
import WidgetHeader from '../../molecules/WidgetHeader.mole';
import Widget from '../../components/widget/Widget.component';
import moment from 'moment';
import NewsFeed from '../../components/news-feed/NewsFeed';
import { cleanEmpty } from '../../helpers/Helpers';
import MarketMovers from '../../components/market-movers/MarketMovers';

const ResponsiveGridLayout = WidthProvider(Responsive);

function StocksDashboard({ profile }) {
  const [stocks, setStocks] = useState([]);
  const [stocksAsObject, setStocksAsObject] = useState({});
  const [stocksPreviousDayData, setStocksPreviousDayData] = useState({});
  const [stocksLiveData, setStocksLiveData] = useState({});
  const [dashboardLayout, setDashboardLayout] = useState([
    { i: 'chat', x: 0, y: 0, w: 35, h: 7, minW: 7, minH: 7 },
    { i: 'candlestick-chart', x: 35, y: 0, w: 50, h: 7, minW: 7, minH: 7 },
    { i: 'watchlist', x: 85, y: 0, w: 15, h: 13, minW: 7, minH: 7 },
    { i: 'market-movers', x: 85, y: 0, w: 15, h: 13, minW: 7, minH: 7 },
    { i: 'news-feed', x: 0, y: 1, w: 35, h: 7, minW: 35, minH: 7 },
  ]);
  const [dashboardLayoutLoaded, setDashboardLayoutLoaded] = useState(false);
  const [gridRowHeight, setGridRowHeight] = useState(500);
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (!firebase.auth().currentUser) return;
    const userID = firebase.auth().currentUser.uid;
    startStreamingMarketMovers();
    firebase
      .firestore()
      .collection('profiles')
      .doc(userID)
      .collection('watchlist')
      .onSnapshot((querySnapshot) => {
        const previousDayData = {};
        var stocksFromDatabase = [];
        var stocksFromDatabaseAsObject = {};
        var loadStockInfoPromises = [];
        // for each stock from database
        querySnapshot.forEach((doc) => {
          let stock = doc.data();
          startStreamingStock(stock);
          stocksFromDatabaseAsObject[stock.ticker] = true;
          // push the request into array
          loadStockInfoPromises.push(Promise.resolve(stocksFromDatabase.push(stock)));
        });
        // after all data is loaded, setStocks for UI to access
        return Promise.all(loadStockInfoPromises).then(() => {
          setStocks(stocksFromDatabase);
          setStocksAsObject(stocksFromDatabaseAsObject);
          return Promise.all(
            stocksFromDatabase.map((stock) => {
              return firebase
                .firestore()
                .collection('endOfDay')
                .doc(stock.ticker)
                .get()
                .then((previousDaySnapshot) => {
                  let endOfDay = previousDaySnapshot.data();
                  previousDayData[stock.ticker] = endOfDay.prevDay;
                });
            })
          ).then(() => {
            setStocksPreviousDayData(previousDayData);
            // updateRowHeight();
          });
        });
      });

    stockStream.subscribe((updatedStocksData) => {
      setStocksLiveData({ ...updatedStocksData });
    });

    firebase
      .firestore()
      .collection('activeUsers')
      .where('timestamp', '>', moment().utc().subtract(10, 'minutes').valueOf())
      .onSnapshot((activeSnapshot) => {
        let users = [];
        activeSnapshot.docs.forEach((doc) => {
          users.push(doc.data());
        });
        setActiveUsers(users);
      });

    firebase
      .firestore()
      .collection('profiles')
      .doc(userID)
      .collection('settings')
      .doc('layouts')
      .get()
      .then((doc) => {
        setDashboardLayoutLoaded(true);
        if (!doc.data()) return;
        setDashboardLayout(doc.data().dashboard);
      });

    return () => {
      setStocks([]);
      setStocksLiveData({});
    };
  }, [firebase, firebase.auth(), firebase.auth().currentUser]);

  useEffect(() => {
    if (!profile) return;
    const stocksFromDatabase = [];
    const tickers = profile.tickers || [];
    for (var x = 0; x < tickers.length; x++) {
      let ticker = tickers[x];
      if (!stocksPreviousDayData[ticker]) {
        startStreamingStock(ticker);
        setStocksAsObject({
          ...stocksAsObject,
          [ticker]: true,
        });
        // push the request into array
        stocksFromDatabase.push({ ticker: ticker });
      }
    }
    return Promise.all(
      stocksFromDatabase.map((stock) => {
        return firebase
          .firestore()
          .collection('endOfDay')
          .doc(stock.ticker)
          .get()
          .then((previousDaySnapshot) => {
            let endOfDay = previousDaySnapshot.data();
            setStocksPreviousDayData({
              ...stocksPreviousDayData,
              [stock.ticker]: endOfDay.prevDay,
            });
            console.log(stock);
          });
      })
    );
  }, [profile]);

  // const updateRowHeight = () => {
  //   dashboardLayout.forEach((layoutItem) => {
  //     let itemHeight = document.getElementsByClassName(layoutItem.i)[0].scrollHeight;
  //     if (itemHeight > gridRowHeight) {
  //       setGridRowHeight(itemHeight);
  //     }
  //   });
  // }

  const getComponent = (name) => {
    if (name === 'tickers') {
      return (
        <TickerTape
          stocksLiveData={stocksLiveData}
          stocksPreviousDayData={stocksPreviousDayData}
          stocksAsObject={stocksAsObject}
          profile={profile}
        ></TickerTape>
      );
    } else if (name === 'candlestick-chart') {
      return (
        // <Chart id="dashboard-candlestick-chart" />
        <CandlestickChart id='dashboard-candlestick-chart-tv'></CandlestickChart>
      );
    } else if (name === 'watchlist') {
      return (
        <Watchlist
          previousDayData={stocksPreviousDayData}
          stocksLiveData={stocksLiveData}
          stocks={stocks}
        />
      );
    } else if (name === 'market-movers') {
      return (
        <MarketMovers
          stocksLiveData={stocksLiveData}
          gainers={stocksLiveData?.marketMovers?.gainers}
          losers={stocksLiveData?.marketMovers?.losers}
        />
      );
    } else if (name === 'chat') {
      return <Chat profile={profile}></Chat>;
    } else if (name === 'news-feed') {
      return <NewsFeed></NewsFeed>;
    }
  };

  const onLayoutChange = (newLayout) => {
    console.log('newLayout = ', newLayout);
    if (!firebase.auth().currentUser || !dashboardLayoutLoaded) return;
    const userID = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection('profiles')
      .doc(userID)
      .collection('settings')
      .doc('layouts')
      .set({
        dashboard: cleanEmpty(newLayout),
      });
  };

  return (
    <div className='Dashboard'>
      <Row className='m-0'>
        <Sidebar />

        <Col className='main-col p-0'>
          <Header stocks={stocksAsObject} profile={profile}></Header>

          <div className='pb-5 px-3'>
            <Container className='px-0' style={{ maxWidth: '100%' }}>
              <Row>
                <Col className='col-12'>
                  {getComponent('tickers')}

                  <div className='bg-light'>
                    <span className='px-2'>Active Users:</span>
                    {activeUsers.map((user, index) => {
                      return (
                        <span className='px-2' key={'active-users-' + index}>
                          {index === activeUsers.length - 1 ? user.name : user.name + ','}
                        </span>
                      );
                    })}
                  </div>

                  {dashboardLayoutLoaded && (
                    <ResponsiveGridLayout
                      className='layout'
                      layouts={{
                        lg: dashboardLayout,
                        md: dashboardLayout,
                        sm: dashboardLayout,
                        xs: dashboardLayout,
                        xxs: dashboardLayout,
                      }}
                      onLayoutChange={onLayoutChange}
                      rowHeight={50}
                      useCSSTransforms={true}
                      resizeHandles={['se', 's', 'sw']}
                      draggableHandle='.draggable-handle'
                      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                      cols={{ lg: 100, md: 100, sm: 100, xs: 100, xxs: 100 }}
                      allowOverlap={false}
                    >
                      <Widget key='chat'>
                        <Widget.Header />
                        <Widget.Body>{getComponent('chat')}</Widget.Body>
                      </Widget>

                      <Widget key='watchlist'>
                        <Widget.Header />
                        <Widget.Body className='overflow-auto'>
                          {getComponent('watchlist')}
                        </Widget.Body>
                      </Widget>

                      <Widget key='market-movers'>
                        <Widget.Header />
                        <Widget.Body className='overflow-auto'>
                          {getComponent('market-movers')}
                        </Widget.Body>
                      </Widget>

                      <Widget key='candlestick-chart'>
                        <Widget.Header />
                        <Widget.Body>{getComponent('candlestick-chart')}</Widget.Body>
                      </Widget>

                      <Widget key='news-feed'>
                        <WidgetHeader />
                        <Widget.Body>{getComponent('news-feed')}</Widget.Body>
                      </Widget>
                    </ResponsiveGridLayout>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StocksDashboard;
