import { Button, ButtonGroup, Dropdown, Form, Image, Overlay, Popover } from 'react-bootstrap';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { GrAttachment, GrSend } from 'react-icons/gr';
import { BsThreeDots } from 'react-icons/bs';
import firebase from 'firebase/compat/app';
import './messenger.style.scss';
import Hide from '../../molecules/Hide.mole';
import { arrayUnion, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import useOnSnapshot from '../../hooks/useOnSnapshot.hook';
import MentionPanel from '../mention-panel/MentionPanel.component';
import { focusElement, insertElementAtCaret } from '../../helpers/Dom.helpers';
import { Link } from 'react-router-dom';

function Messenger({ children }) {
  <div className=''>{children}</div>;
}

const MessageView = ({ message, profiles, onDelete }) => {
  // console.log(profiles)

  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const user = firebase.auth().currentUser;
  const userId = user?.uid;
  const profile = profiles[message?.senderID];
  const profileLink = `/profile/${profile?.id}`;

  const friend = useOnSnapshot(doc, ['profiles', userId, 'friends', message.senderID], null);

  const sendFriendRequest = async () => {
    const friendRef = doc(db, 'profiles', message.senderID, 'friends', userId);
    const userRef = doc(db, 'profiles', userId, 'friends', message.senderID);

    await Promise.all([
      setDoc(friendRef, {
        name: user.displayName,
        id: userId,
        status: 'pending',
        // image: user.profileImage
      }),
      setDoc(userRef, {
        name: profile.name,
        id: message.senderID,
        status: 'sent',
        image: profile.profileImage ? profile.profileImage : 'https://via.placeholder.com/150',
      }),
    ]);
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(containerRef.current);
  };

  return (
    <div className='d-flex mt-2 message-view'>
      <Overlay
        show={show}
        target={target}
        placement='right-start'
        container={containerRef}
        containerPadding={20}
        onHide={() => setShow(false)}
        rootClose
      >
        <Popover className='chat-profile-popover'>
          <Popover.Header as='h3' className='header'>
            <div className='d-flex justify-content-between'>
              <div className='avatar'>
                <div className='indicator'></div>
                <Link to={profileLink}>
                  <Image
                    roundedCircle
                    fluid
                    className=''
                    src={profile?.profileImage}
                    alt='avatar'
                  />
                </Link>
              </div>
              <div className=''></div>
              <div className=''>
                <Button className=''>Follow</Button>
              </div>
            </div>
          </Popover.Header>
          <Popover.Body className='body'>
            <Link to={profileLink} className='text-decoration-none'>
              <p className='mb-0 text-black'>
                <strong>{profile?.name}</strong>#3824
              </p>
            </Link>

            <div className='d-flex'>
              <Button
                disabled={friend || profile?.id === userId}
                onClick={sendFriendRequest}
                className='mx-0 flex-1'
              >
                Add Friend
              </Button>
              <Link to={profileLink}>
                <Button className='mx-0 flex-1 ms-1'>View Profile</Button>
              </Link>
            </div>

            <Form.Group className='mt-3' controlId='formBasicEmail'>
              <Form.Control type='text' placeholder={`Message @${profile?.name}`} />
            </Form.Group>
          </Popover.Body>
        </Popover>
      </Overlay>

      <div onClick={handleClick} className='cursor-pointer '>
        <div className='profile-active small' style={{ width: '40px', height: '40px' }}>
          <img
            className='me-2 w-100 h-100'
            style={{
              borderRadius: '100%',
            }}
            // width="40px"
            // height="40px"
            src={profiles[message?.senderID]?.profileImage}
          />
        </div>
      </div>

      <div
        className='flex-1'
        style={{
          color: 'white',
        }}
      >
        <div className='d-flex justify-content-between'>
          <div className=''>
            <span
              className='me-2 cursor-pointer'
              style={{
                color: 'green',
                fontWeight: 600,
              }}
              onClick={handleClick}
              ref={containerRef}
            >
              {profiles[message?.senderID]?.name}
            </span>
            <span
              style={{
                fontSize: '13px',
                color: 'gray',
              }}
            >
              {/* Today at 8:43 AM */}
              {moment(message.timestamp).isAfter(moment().startOf('day'))
                ? moment(message.timestamp).format('[Today at] h:mm a')
                : moment(message.timestamp).isAfter(moment().startOf('day').subtract(1, 'day'))
                ? moment(message.timestamp).format('[Yesterday at] h:mm a')
                : moment(message.timestamp).format('dddd h:mma D MMM YYYY')}
            </span>
          </div>

          <div className='message-options'>
            <Dropdown>
              <Dropdown.Toggle variant='' id='dropdown-basic'>
                Options
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>Others</Dropdown.Item>
                <Hide open={userId !== message?.senderID}>
                  <Dropdown.Item disabled={friend} onClick={sendFriendRequest}>
                    <Hide open={friend} fallback='Add Friend'>
                      {friend?.status}
                    </Hide>
                  </Dropdown.Item>
                </Hide>
                <Hide open={userId === message.senderID}>
                  <Dropdown.Item onClick={() => onDelete(message.id, message.senderID)}>
                    Delete Message
                  </Dropdown.Item>
                </Hide>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <p className='message text-dark'>{message.content}</p>
      </div>
    </div>
  );
};

function MessageSent({ onSent, profiles }) {
  const messageRef = useRef(null);
  const atRef = useRef(null);
  const [mentionPanelOpen, setMentionPanelOpen] = useState(false);

  const handleSent = () => {
    if (typeof onSent === 'function' && messageRef.current) {
      onSent(messageRef.current.textContent);
      messageRef.current.textContent = '';
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleSent();
    }
  };

  // Prevents the linebreak in textinput on enter press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
    }

    if (event.key === '@' || event.code === '50') {
      event.preventDefault();
      const ref = messageRef.current;
      setMentionPanelOpen(true);

      if (ref) {
        const element = document.createElement('span');
        element.contentEditable = 'false';
        element.innerHTML = '@';
        atRef.current = element;
        insertElementAtCaret(element);
      }
    }
  };

  const handleMention = (user) => {
    const element = atRef.current;
    const editor = messageRef.current;

    if (element && editor) {
      element.contentEditable = 'false';
      element.innerText = `@${user.name}`;
      element.className = 'bg-info';
      focusElement(editor);
      setMentionPanelOpen(false);
    }
  };

  return (
    <div className='d-flex align-items-center sentMessage-container position-relative px-4'>
      <Hide open={mentionPanelOpen}>
        <MentionPanel
          onMention={handleMention}
          profiles={profiles}
          onClickAway={() => setMentionPanelOpen(false)}
        />
      </Hide>
      <div>
        <span>
          <GrAttachment fill='red' />
        </span>
      </div>
      <div
        className='flex-1 message-input d-block'
        role='textbox'
        contentEditable
        placeholder='message...'
        ref={messageRef}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
      ></div>

      <Button
        className='d-flex align-items-center px-4 sent-button border-0 text-light'
        onClick={handleSent}
      >
        Send <GrSend className='ms-2' />
      </Button>
    </div>
  );
}

Messenger.Sent = MessageSent;
Messenger.View = MessageView;
export default Messenger;
