
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';


// Your web app's Firebase configuration
const config = {
    apiKey: 'AIzaSyA4Sckh-Ms9pztmi6DKJOufos7EJeYzELU',
    authDomain: 'automated-stock-trading.firebaseapp.com',
    projectId: 'automated-stock-trading',
    storageBucket: 'automated-stock-trading.appspot.com',
    messagingSenderId: '636493775116',
    appId: '1:636493775116:web:8e0ee06d8013ed377d0929',
};

firebase.initializeApp(config);

export const db = firebase.firestore()
export const storage = firebase.storage()
