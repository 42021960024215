import React from 'react'
import { BsArrowsMove } from "react-icons/bs"

function WidgetHeader({label}) {
    return (
        <div className="draggable-handle bg-dark text-light d-flex align-items-center px-2 py-2">
            <BsArrowsMove className="h4 mb-0"></BsArrowsMove>
            <h4 className="ms-2 lh-1 mb-0">{label}</h4>
        </div>
    )
}

export default WidgetHeader