import { autocomplete } from '@algolia/autocomplete-js';
import React, {createElement, Fragment, useEffect, useRef, useState} from 'react';
import { render } from 'react-dom';
import {RiFilePaper2Line} from "react-icons/ri";
import {Link} from "react-router-dom";
import {addStockToWatchlist, removeStockFromWatchlist} from "../api/stocks.api";
import {BsHeart, BsHeartFill, BsTrash} from "react-icons/bs";

export function Autocomplete(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const search = autocomplete({
            container: containerRef.current,
            renderer: { createElement, Fragment },
            render({ children }, root) {
                render(children, root);
            },
            placeholder: 'Search Ticker',
            ...props,
        });

        return () => {
            search.destroy();
        };
    }, [props.stocks]);

    return <div className={`search-container col-${props.col === undefined ? '6' : props.col
        } rounded`} ref={containerRef} />;
}

export function SearchResultItem({ hit, components, navigate, stocks }) {

    return (
        <a className="aa-ItemLink" style={{zIndex: 1056}}>
            <div className="aa-ItemContent">
                <div className="aa-ItemTitle" onClick={() => navigate(hit.ticker)}>
                    {/*<Link to="/options">*/}
                        <components.Highlight hit={hit} attribute="ticker" />
                        &nbsp; &nbsp;
                        <components.Highlight hit={hit} attribute="name" />
                    {/*</Link>*/}
                </div>
                <span className="float-right">
                    { stocks && stocks[hit.ticker] ?
                        <BsHeartFill onClick={() => removeStockFromWatchlist({ticker: hit.ticker})}></BsHeartFill>
                        :
                        <BsHeart onClick={() => addStockToWatchlist({ticker: hit.ticker})}></BsHeart>
                    }
                </span>
            </div>
        </a>
    );
}
