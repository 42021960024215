
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { Button, Modal } from 'react-bootstrap';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

function handleGoogleLogin() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
    }).catch((error) => {
      console.log(error)
    });
}

function AuthModal(props) {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <React.Fragment>
      <p variant="primary" onClick={handleShow} className="mb-0">
        {props.linkText}
      </p>

      <Modal show={show} onHide={handleClose} size="md" >
        <Modal.Body >
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
          <div className="align-center text-center mt-3 w-max mx-auto">
            <Button className="" onClick={handleGoogleLogin}>Login With Google</Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default AuthModal;