import * as React from 'react';
import './CandlestickChart.css';
import { widget } from '../../charting_library';

function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

let isTest = window.location.hostname.includes('localhost');
const datafeedUrl = isTest ? 'http://localhost:8080' : 'https://automated-stock-trading.uc.r.appspot.com/'

export class CandlestickChart extends React.PureComponent {
    static defaultProps = {
        symbol: 'SPY',
        interval: 'D',
        datafeedUrl: datafeedUrl,
        libraryPath: '/charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
    };

    tvWidget = null;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount() {
        if (!window.Datafeeds) {
            return setTimeout(() => {
                this.componentDidMount()
            }, 600);
        }
        const widgetOptions = {
            symbol: this.props.ticker || this.props.symbol,
            // BEWARE: no trailing slash is expected in feed URL
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
            interval: this.props.interval,
            container: this.ref.current,
            library_path: this.props.libraryPath,

            locale: getLanguageFromURL() || 'en',
            disabled_features: ['use_localstorage_for_settings'],
            enabled_features: ['study_templates'],
            charts_storage_url: this.props.chartsStorageUrl,
            charts_storage_api_version: this.props.chartsStorageApiVersion,
            client_id: this.props.clientId,
            user_id: this.props.userId,
            fullscreen: this.props.fullscreen,
            autosize: this.props.autosize,
            studies_overrides: this.props.studiesOverrides,
        };

        const tvWidget = new widget(widgetOptions);
        this.tvWidget = tvWidget;

        tvWidget.onChartReady(() => {
            tvWidget.headerReady().then(() => {
                const button = tvWidget.createButton();
                button.setAttribute('title', 'Click to show a notification popup');
                button.classList.add('apply-common-tooltip');
                button.addEventListener('click', () => tvWidget.showNoticeDialog({
                    title: 'Notification',
                    body: 'TradingView Charting Library API works correctly',
                    callback: () => {
                        console.log('Noticed!');
                    },
                }));

                button.innerHTML = 'Check API';
            });
        });
    }

    componentWillUnmount() {
        if (this.tvWidget !== null) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
    }

    render() {
        return (
            <div
                ref={ this.ref }
                className={ 'TVChartContainer' }
            />
        );
    }
}
