import {Card} from "react-bootstrap";
import {MdOutlineClose} from "react-icons/md";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {removeStockFromWatchlist} from "../../api/stocks.api";
import {getStockCardWidgetOptions} from "../../helpers/Helpers";
import Chart from "../chart/Chart";

function StockCard(props) {

    let navigate = useNavigate();

    let stock = props.stock;

    useEffect(() => {
        const initializedWidgetScripts = [];

        // if (!document.getElementById('script-stock-trading-view-' + stock.ticker)) {
        //     const widgetScriptMiniChart = document.createElement('script');
        //     widgetScriptMiniChart.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
        //     widgetScriptMiniChart.async = true;
        //     widgetScriptMiniChart.id = 'script-stock-trading-view-' + stock.ticker
        //     widgetScriptMiniChart.innerHTML = getStockCardWidgetOptions('miniChart', stock);
        //     document.getElementById('stock-trading-view-' + stock.ticker).appendChild(widgetScriptMiniChart);
        //     initializedWidgetScripts.push(widgetScriptMiniChart);
        // }
        // if (!document.getElementById('script-stock-trading-view2-' + stock.ticker)) {
        //   const widgetScriptMiniTVSentiment = document.createElement('script');
        //   widgetScriptMiniTVSentiment.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
        //   widgetScriptMiniTVSentiment.async = true;
        //   widgetScriptMiniTVSentiment.id = 'script-stock-trading-view2-' + stock.ticker
        //   widgetScriptMiniTVSentiment.innerHTML = getStockCardWidgetOptions('tvSentiment', stock.ticker);
        //   document.getElementById('stock-trading-view2-' + stock.ticker).appendChild(widgetScriptMiniTVSentiment);
        // }

        return () => {
            initializedWidgetScripts.forEach((widgetScript) => {
                document.body.removeChild(widgetScript);
            })
        }
    }, [stock])


    return <Card style={{minWidth: '600px', borderRadius: '1rem'}}>
        <Card.Body>
            <span className="cursor-pointer">
                <Card.Title className='text-dark' style={{fontSize: 25}} onClick={() => navigate("/dashboard/" + stock.ticker)}>
                    {stock?.ticker}
                    <MdOutlineClose className="close-icon" onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        return removeStockFromWatchlist(stock);
                    }} />
                </Card.Title>
            </span>
            <div id={'stock-trading-view-' + stock.ticker} className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget"></div>
            </div>

            <hr />

            <Chart id={stock.ticker + '-candlestick-chart'}></Chart>
            {/*
              <div id={'stock-trading-view2-' + stock.ticker} className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget"></div>
              </div>
              */}

            {/*<Card.Text className='text-dark mb-4' style={{fontSize: 18, textAlign: 'left'}}>${stock?.c}</Card.Text>*/}
        </Card.Body>
    </Card>
}

export default StockCard;
