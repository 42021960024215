import clsx from "clsx"
import React from 'react'
import { BsArrowsMove } from "react-icons/bs"

const Widget = React.forwardRef(({children,style,className,...props},ref)=> {
  return (
    <div className={clsx("d-flex flex-column",className)} style={style} ref={ref} {...props}>
        {children}
    </div>
  )
})

Widget.Header = function ({label}) {
    return (
        <div className="draggable-handle bg-dark text-light d-flex align-items-center px-2 py-2">
            <BsArrowsMove className="h4 mb-0"></BsArrowsMove>
            <h4 className="ms-2 lh-1 mb-0">{label}</h4>
        </div>
    )
}

Widget.Body = function ({children,className}) {
    return (
        <div className={clsx("bg-light flex-1",className)} style={{maxHeight: '100%', overflowY: 'scroll'}}>
            {children}
        </div>
    )
}

export default Widget;
