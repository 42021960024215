import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import React, { useState } from 'react';
import { Col, Container, Form, Row, Button, Spinner } from 'react-bootstrap';
import Sidebar from '../../components/sidebar/Sidebar';
import { MdModeEdit } from 'react-icons/md';

import './Settings.css';
import { db, storage } from '../../firebase';
import { BiPencil } from 'react-icons/bi';

const ref = storage.ref();

const Settings = ({ profile, setProfile }) => {
  const [profileBlob, setProfileBlob] = useState('');
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const blob = URL.createObjectURL(fileUploaded);
    setProfileBlob(blob);
    setFile(fileUploaded);
  };

  let profilePic = profileBlob || profile?.profileImage || 'https://via.placeholder.com/150';

  const uploadFile = async (file) => {
    try {
      const profilesRef = ref.child(`profiles/${profile.uid}`);
      const snapshot = await profilesRef.put(file);
      const downloadUrl = await snapshot.ref.getDownloadURL();
      return downloadUrl;
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfileUpload = async () => {
    setLoading(true);
    let url = '';
    if (profileBlob !== '') {
      url = await uploadFile(file);
    }

    const docRef = db.doc(`profiles/${profile.uid}`);

    let updateObject = {
      name: profile.name,
    };

    if (url !== '') {
      updateObject.profileImage = url;
    }

    await docRef.update(updateObject);
    setLoading(false);
  };

  return (
    <div className='Dashboard'>
      <Row className='m-0' style={{ height: '100vh' }}>
        <Sidebar />
        <Col className='page p-0'>
          <Container>
            <h5>Edit Settings</h5>
            <div className='position-relative profile-image-container'>
              <div className='profile-active'>
                <img src={profilePic} className='profile-image ' />
              </div>
            </div>

            <div className='position-relative'>
              <input
                type='file'
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ opacity: '0', cursor: 'pointer', height: '100%', width: '100%' }}
                className='position-absolute'
              />
              <button className='btn btn-primary'>Edit Profile Picture</button>
            </div>

            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setProfile({
                      ...profile,
                      name: e.target.value,
                    })
                  }
                  value={profile?.name}
                  className='name-input'
                  type='text'
                  placeholder='Name'
                  name='name'
                ></Form.Control>
                <Form.Label className='mt-3'>E-mail</Form.Label>
                <Form.Control
                  disabled
                  value={profile?.email}
                  className='email-input '
                  type='text'
                  placeholder='email'
                  name='email'
                ></Form.Control>
                <Button disabled={loading} className='mt-2' onClick={handleProfileUpload}>
                  {loading && <Spinner size='sm' className='mx-2' animation='border' />}
                  Save
                </Button>
              </Form.Group>
            </Form>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
